import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULTLANGUAGE } from '@data/i18n.data';
import { EPaymentCards } from '@data/icons.data';
import { SubscriptionPlanType } from '@enums/subscription.enum';
import { environment } from '@environments/environment';
import { IdPAccessTokenResponse } from '@models/responses/response-login';

@Injectable({ providedIn: 'root' })
export class MethodsGeneralService {
  private readonly authHeader = 'Authorization';
  private readonly timeoutHeader = 'timeout';
  private readonly acceptLanguage = 'Accept-Language';
  private readonly idpSub = 'idpSub';
  private readonly managementGroup = 'managementGroup';
  private readonly token = '/token';

  getDataObject(
    object: any,
    param: string,
    secondParam: string,
    defaultReturn: any
  ): any {
    try {
      if (object) {
        return !secondParam ? object[param] : object[param][secondParam];
      } else return defaultReturn;
    } catch (_) {
      return defaultReturn;
    }
  }

  gotToNewTab(url): void {
    window.open(url, '_blank');
  }

  deleteStressM(stg: string): string {
    return stg
      .normalize('NFD')
      .replace(
        // eslint-disable-next-line no-misleading-character-class
        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
        '$1'
      )
      .normalize();
  }

  setImage(item): string {
    switch (item.toLowerCase()) {
      case 'visa':
        return EPaymentCards.VISA;
      case 'master':
      case 'mastercard':
        return EPaymentCards.MASTERCARD;
      case 'discover':
        return EPaymentCards.DISCOVER;
      case 'american_express':
        return EPaymentCards.AMERICAN_EXPRESS;
      case 'diners':
        return EPaymentCards.DINERS;
      case 'jcb':
        return EPaymentCards.JCB;
      default:
        return EPaymentCards.DEFAULT_PAYMENT;
    }
  }

  // TODO: REDO THIS METHOD
  getCardType(number): string {
    number = number.replace(/\s/g, '');
    let re = new RegExp('^4');
    if (number.match(re) != null) {
      return this.setImage('Visa');
    }

    re = new RegExp('^3[47]');
    if (number.match(re) != null) {
      return this.setImage('American_Express');
    }

    if (
      /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
        number
      )
    ) {
      return this.setImage('master');
    }

    re = new RegExp(
      '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
    );
    if (number.match(re) != null) {
      return this.setImage('Discover');
    }

    re = new RegExp('^36');
    if (number.match(re) != null) {
      return this.setImage('Diners');
    }

    re = new RegExp('^35(2[89]|[3-8][0-9])');
    if (number.match(re) != null) {
      return this.setImage('JCB');
    }

    return this.setImage('');
  }

  setStatusName(item, labels): string {
    switch (item) {
      case 1:
      default:
        return labels.pending;
      case 2:
        return labels.success;
      case 3:
        return labels.fail;
    }
  }

  getPlanName(planSelected) {
    return {
      [SubscriptionPlanType.START]: () => SubscriptionPlanType.START_NAME,
      [SubscriptionPlanType.MEDIUM]: () => SubscriptionPlanType.MEDIUM_NAME,
      [SubscriptionPlanType.PLUS]: () => SubscriptionPlanType.PLUS_NAME,
      [SubscriptionPlanType.PARTNER]: () => SubscriptionPlanType.PARTNER_NAME,
    }[planSelected]();
  }

  toQueryString(query): string {
    const parts = [];
    for (const property in query) {
      const value = query[property];
      if (value != null && value != undefined)
        parts.push(
          encodeURIComponent(property) + '=' + encodeURIComponent(value)
        );
    }

    return parts.join('&');
  }

  getHeaders(
    request: HttpRequest<unknown>,
    accessToken: IdPAccessTokenResponse,
    currentlanguage: string,
    idpSub: string,
    activeManagementGroupId: string
  ): HttpRequest<unknown> {
    const {
      SERVER_URL,
      passerIdP: {
        options: { serverUrl },
      },
    } = environment;

    const isApiUrl =
      (!request.url.startsWith(serverUrl) &&
        !request.url.startsWith(SERVER_URL)) ||
      request.url.startsWith(`${serverUrl}${this.token}`);

    if (isApiUrl || !accessToken) {
      return request;
    }

    return request.clone({
      headers: request.headers
        .set(
          this.authHeader,
          `${accessToken.token_type} ${accessToken.access_token}`
        )
        .set(this.timeoutHeader, `${60000}`)
        .set(this.acceptLanguage, currentlanguage || DEFAULTLANGUAGE)
        .set(this.idpSub, idpSub || '')
        .set(this.managementGroup, activeManagementGroupId || '')
        .set('version', environment.deaVersion),
    });
  }
}
