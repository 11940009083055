export const esLocale = {
  language: 'es',
  data: {
    accessSecurity: {
      title: 'ACCESOS Y SEGURIDAD',
      accessRegister: {
        title: 'REGISTRO DE ACCESOS',
        securityProt: 'Protocolos de seguridad',
        securityProtOptions: [
          { act: 1, name: 'Cerrar sesión del usuario seleccionado' },
          { act: 2, name: 'Cerrar todas las sesiones' },
          {
            act: 3,
            name: 'Cerrar todas las sesiones y solicitar autenticación de dos factores',
          },
          {
            act: 4,
            name: 'Cerrar sesión del usuario seleccionado y solicitar autenticación de dos factores',
          },
        ],
        menu: [
          { icon: 'check-box', name: 'Manejar accesos' },
          { icon: 'pending-actions', name: 'Historial de acciones' },
          { icon: 'person-off', name: 'Dar de baja' },
        ],
        actionSuccessTitle: 'ACCIÓN EJECUTADA',
        actionSuccessDescription:
          'La acción se ha realizado con éxito, si tienes consultas puedes comunicarte con soporte Enterprises',
        userEmptyTitle: 'Acciones no encontradas',
        userEmptyDescription: 'Este usuario aún no tiene acciones registradas.',
        companyDataNotFound:
          'Lo sentimos, no se pudo obtener la información de la compañia.',
        checkUser:
          'Debes seleccionar primero el usuario deseado, para proceder con dicha acción.',
        noUnsubscribe:
          'Esta acción no está permitida porque tu role es de menor rango al usuario que deseas dar de baja.',
        unsubscribeTitle: 'Dar de baja',
        unsubscribeDescription:
          'Estás por dar de baja al usuario <b>{{user}}</b>.<br/>¿Deséas continuar?',
        unsubscribe: 'El usuario fue dado de baja con éxito.',
      },
      newAccess: {
        title: 'NUEVO ACCESO',
        subTitle: 'ACCESOS ADMINISTRATIVOS ENTERPRISES',
        desc: 'Permiten crear y modificar suscripciones, así como agregar o eliminar credenciales de bancarios entre otras funciones de tipo administrativo.',
        accountList: [
          {
            title: 'PROPIETARIO DE GRUPO',
            desc: 'Posee acceso a todos los recursos, suscripciones, plataformas y funciones del grupo administrativo al cual pertenece.',
          },
          {
            title: 'ADMINISTRADOR DE GRUPO',
            desc: 'Posee acceso a todos los recursos y funciones del grupo administrativo asignado. No puede registrar ni modificar suscripciones.',
          },
          {
            title: 'DESARROLLADOR',
            desc: 'Posse acceso únicamente a Enterprises Connect y sus funciones de desarrollo.',
          },
        ],
        subscriptionTitle: 'ACCESOS DE SUSCRIPCIONES',
        subscriptionDescription:
          'Niveles de acceso para los usuarios que trabajarán específicamente en alguna de las suscripciones de empresa. No poseen permiso de ingreso a los módulos administrativos.',
        subscriptionList: [
          {
            title: 'ADMINISTRADOR DE SUSCRIPCIÓN',
            desc: 'Posee acceso a todos los módulos y funciones de la suscripción asignada.',
          },
          {
            title: 'VENDEDOR',
            desc: 'Posee acceso por defecto a los módulos de: Boletería y Monitoreo.',
            privileges: [
              { idModule: 2, name: 'Conductores', act: false },
              { idModule: 3, name: 'Roles', act: false },
              { idModule: 5, name: 'Mantenimiento rutas', act: false },
              { idModule: 6, name: 'Registro vehículos', act: false },
              { idModule: 8, name: 'Recaudo y liquidaciones', act: false },
              { idModule: 9, name: 'Ubicaciones', act: false },
              { idModule: 40, name: 'Logística', act: false },
            ],
          },
          {
            title: 'ENROLADOR/OPERATIVO',
            desc: 'Posee acceso por defecto a los módulos de Roles, Conductores y Monitoreo.',
            privileges: [
              { idModule: 4, name: 'Boletería', act: false },
              { idModule: 5, name: 'Mantenimiento rutas', act: false },
              { idModule: 6, name: 'Registro vehículos', act: false },
              { idModule: 8, name: 'Recaudo y liquidaciones', act: false },
              { idModule: 9, name: 'Ubicaciones', act: false },
              { idModule: 40, name: 'Logística', act: false },
            ],
          },
          {
            title: 'ANALISTA',
            desc: 'Posse acceso a: Perfil - Monitoreo Vehicular - Estadísticas - Recaudo y Bancos (pero solo recaudo en curso).',
          },
          {
            title: 'MECÁNICO',
            desc: 'Posse acceso a: Perfil - Monitoreo Vehicular - Mecánica - Registro Vehículos - Roles (pero no ha crear nuevo role).',
          },
        ],
        rifhtDesc:
          'Concede acceso a miembros de tu organización para colaboren contigo en la gestión de cuenta o suscripciones.',
        accountAccess: 'Accesos administrativos',
        subscriptionAccess: 'Accesos de suscripciones',
        accessSuccessTitle: 'ACCESO ENVIADO CON ÉXITO',
        accessSuccessDescription: 'Has enviado una invitación de acceso a ',
        accessSuccessDescription2: ' con permisos de ',
        userExistTitle: 'USUARIO YA EXISTE',
        userExistDescription: 'El email ',
        userExistDescription2:
          ' parece que ya existe como usuario de cuenta, por favor revisa e intenta de nuevo.',
      },
      modal: {
        title: 'CONTROL DE ACCESOS',
        currentAccess: 'Acceso actual',
        newAccess: 'Nuevo acceso',
      },
      accessManagement: {
        warningIsOwner:
          'Este usuario es de tipo <b>"Propietario de grupo"</b>, lo que significa que posee un acceso universal a todo los recursos, plataformas y funciones del grupo de administrativo. No puedes bajar de nivel este usuario,',
        accessSubscriptionTitle: 'ACCESOS SUSCRIPCIONES',
        warningDES:
          'Configura el nivel de acceso en cada suscripción que desees asignar.',
        changeTitle: 'CONCEDER ACCESO',
        changeDescription:
          'Estás por conceder un nuevo acceso al sistema para este usuario ¿Deseas continuar?',
        revokeTitle: 'REVOCAR ACCESO',
        revokeDescription:
          'Estás por eliminar el acceso al sistema para este usuario ¿Deseas continuar?',
        accessDeleteSuccess: 'Acceso revocado con éxito.',
        accessChangeSuccess: 'Acceso concedido con éxito.',
        descriptionRoleDEA: [
          {
            role: 20,
            description:
              'Posee acceso a todos los recursos, suscripciones, plataformas y funciones del grupo del grupo administrativo al cual pertenece.',
          },
          {
            role: 21,
            description:
              'Posee acceso a todos los recursos y funciones del grupo administrativo para el grupo asignado. No puede registrar ni modificar suscripciones.',
          },
          {
            role: 22,
            description:
              'Posse acceso a Enterprises Connect y sus funciones de desarrollo. No posee acceso a suscripciones.',
          },
        ],
        descriptionRoleDES: [
          {
            role: 1,
            description:
              'Posee acceso a todos los módulos y funciones de la suscripción asignada.',
          },
          {
            role: 2,
            description:
              'Accesos a los módulos de boletería, ubicaciones, monitoreo y soporte.',
          },
          {
            role: 3,
            description:
              'Accesos a los módulos de roles, conductores, ubicaciones, registro vehículos y monitoreo y soporte.',
          },
          {
            role: 4,
            description:
              'Accesos a los módulos de estadísticas y monitoreo y soporte.',
          },
          {
            role: 5,
            description:
              'Accesos a los módulos de roles, mecánico, registro vehículos y monitoreo y soporte.',
          },
        ],
        managementMenu: [{ icon: 'person-remove', name: 'Revocar acceso' }],
      },
    },
    accountPayment: {
      title: 'CUENTAS PAYMENTS',
      desc: 'Sistema de monitoreo y control transaccional impulsado con la tecnología y sistemas gateway de Passer Payments.',
      emptyDescription:
        'No hay suscripciones creadas. Puedes crear una nueva en la opción del menu "Suscripciones" en el tab de "NUEVA SUSCRIPCIÓN".',
      noSelection:
        'Primero debes seleccionar una suscripción para ver sus transacciones.',
      transaction: {
        tabs: [
          {
            name: 'TRANSACCIONES',
            action: 'transaction',
          },
          {
            name: 'CLIENTES',
            action: 'client',
          },
          {
            name: 'MÉTODOS DE PAGO REGISTRADOS',
            action: 'register-payment',
          },
        ],
        menu: [
          {
            icon: 'description',
            name: 'Detalles',
          },
        ],
        sixDigits: 'Primeros 6 dígitos',
        fourDigits: 'Últimos 4 dígitos',
        otherTransaction: 'Otras transacciones relacionadas',
        errorRefund:
          'La transacción debe estar en estado de <b>Aprobada</b> para poder realizar una reversión.',
      },
      client: {
        menu: [{ icon: 'DEFAULT_PAYMENT', name: 'Tarjetas asociadas' }],
        paymentModals: 'MÉTODOS DE PAGO ASOCIADOS',
        noPaymentMethodTitle: 'Sin métodos de pago',
        noPaymentMethodDescription:
          'Este usuario no tiene métodos de pago asociados.',
        status: {
          inactive: 'Inactiva',
          active: 'Activa',
          locked: 'Bloqueada por el usuario',
          sysLocked: 'Bloqueada por el sistema',
          principal: 'Principal',
        },
      },
      status: {
        approved: 'Aprobada',
        declined: 'Rechazada',
        refunded: 'Reintegrada',
        none: 'N/A',
        pending: 'Pendiente',
      },
    },
    accountProduct: {
      account: {
        title: 'Cuenta Passer',
        desc: 'Tu cuenta Passer es un acceso universal que podrás utilizar para acceder a todas las plataformas Passer y sus funciones.<br><br>Cuando modificas los datos de cuenta se modificarán de manera general, por lo que asegúrate de recordarlos y realizar las verificaciones que correspondan.',
        paymentTitle: 'Productos Payments',
        paymentDescription:
          'Si aún no tienes una cuenta bancaria empresarial puedes ingresar a "Passer Payments" y solicitar tu cuenta Banco Nacional o tus credenciales de ecommerce.',
        credentialsTitle: 'CREDENCIALES DE ACCESO',
        credentialsDescription:
          'Si modificas tus credenciales presiona "Enviar código" para recibir un código vía SMS en tu dispositivo móvil o cuenta de email.',
        pinDesc:
          'El PIN de cuenta lo usarás en todas tus transacciones y compras de los servicios Passer.',
        accountUpdate: 'Cuenta Passer actualizada con éxito.',
        twoSteps:
          'Activa o desactiva la verificación de 2 pasos de tu cuenta Passer. Te recomendamos tener esta opción activa para mayor seguridad.',
      },
      product: {
        title: 'Passer payments',
        tabs: [
          {
            name: 'NUEVA CUENTA BANCARIA',
            action: 'newAccount',
          },
          {
            name: 'SUSCRIPCIONES CONECTADAS',
            action: 'connectedSubscriptions',
          },
          {
            name: 'CREDENCIALES ECOMMERCE',
            action: 'ecommerceCredentials',
          },
          {
            name: 'CREDENCIALES SINPE',
            action: 'sinpeCredentials',
          },
        ],
        connectedSubscriptions: {
          title: 'Suscripciones conectadas',
          description:
            'Las suscripciones conectadas ya tienen asignados los credenciales de procesamiento online o pago electrónico. Por favor, ',
          view: 'consulta el descargo de responsabilidad aquí.',
          menu: [
            { icon: 'edit', name: 'Editar' },
            {
              icon: 'credit-card-off',
              name: 'Desconectar pagos con tarjeta',
            },
            { icon: 'account-balance', name: 'Desconectar SINPE' },
          ],
          connectSubscription: 'Conectar suscripción',
          connectedCredentialsTitle: 'CREDENCIALES CONECTADOS',
          connectedCredentialsDescription:
            'Credenciales actualmente conectados a la suscripción seleccionada',
          payCardTitle: 'PAGOS CON TARJETA',
          payCardDescription: 'Credenciales para recibir pagos con tarjeta',
          sinpePayTitle: 'PAGOS VÍA SINPE',
          sinpePayDescription:
            'Credenciales para recibir pagos a través del sistema SINPE',
          sinpeActivated: 'Se activó SINPE para venta online',
          ibanActivated:
            'Se activó la cuenta IBAN para liquidación de fondos de pago electrónico',
          modifyTitle: 'MODIFICAR CREDENCIALES',
          modifyDescription:
            'Al modificar los credenciales todos los fondos serán redireccionados a las cuentas bancarias asociadas',
          emptyTitle: 'Sin suscripciones conectadas',
          emptyDescription:
            'No hay suscripciones conectadas. Puedes crear una nueva, o bien, modificar una anterior.',
          ibanAccount: 'Cuenta IBAN (SINPE)',
          bank: 'Banco (SINPE)',
          owner: 'Dueño cuenta (SINPE)',
          warningTitle: 'ADVERTENCIA',
          warningDescription:
            'Estás por desconectar los credenciales de procesamiento para recepción de pagos con tarjeta. Si lo deseas mejor puedes modificar los credenciales <b>¿Deseas continuar?</b>',
          warningSinpeDescription:
            'Estás por desconectar los credenciales de procesamiento para recepción de pagos a través de SINPE. Si lo deseas mejor puedes modificar los credenciales <b>¿Deseas continuar?</b>',
          warnSubscriptionTypeTitle: 'ADVERTENCIA - TIPO SUSCRIPCIÓN',
          warnSubscriptionTypeDescription:
            'Esta suscripción es de tipo Opening Online, por lo cual es requerido que tenga asociados credenciales de procesamiento online. Puedes cambiar el tipo de suscripción o bien, modificar los credenciales.',
          disconnectCredentials:
            'Las credenciales de procesamiento para recepción de pagos con tarjeta se han desconectado con éxito.',
        },
        modals: {
          newCredential: {
            title: 'AGREGAR NUEVO CREDENCIAL',
            desc: 'Si aún no tienes credenciales para procesamiento de pago con tarjetas utiliza la sección <b>"Nueva cuenta bancaria"</b> para solicitarlos.',
            incorrectCedentialTitle: 'CREDENCIALES INCORRECTOS',
            incorrectCedentialDescription:
              'Lo sentimos, los credenciales que has ingresado parecen no estar correctos, por favor revisa y trata de nuevo.',
            owner:
              'Los credenciales de cuenta ingresados se encuentran asociados el email ',
            owner2:
              '. Por favor asegúrate que este sea el email que se registro a tu cuente con tu entidad bancaria.',
            agree:
              'Estoy de acuerdo con asociar las credenciales de ecommerce asociados al email indicado, así como el ID de terminal y de afiliado bancario.',
            appNameTooltip: 'Nombre de usuario que el banco te ha asignado.',
            appPasswordTooltip:
              'Contraseña asociada a tu AppName. Por favor no reveles nunca este dato.',
            credntialsSuccess: 'Nueva credencial bancaria creada con éxito',
            validCredentials: 'Debes validar tus credenciales para continuar',
            terminalID: 'Terminal ID',
            terminalTool:
              'Identificador de la terminal de comercio electrónico que el banco te ha asignado.',
            bankAffiliate: 'Afiliado banco',
            bankAffiliateTool:
              'Código de identificación de cliente que tienes ante el banco.',
          },
        },
        newAccount: {
          title:
            'Gestionar apertura de cuenta bancaria y credenciales para ecommerce',
          desc: 'De forma sencilla y directa puedes realizar la solicitud para la apertura de la cuenta bancaria para tu empresa así como los credenciales para ecommerce. Esto te permitirá conectar tus suscripciones para procesar ventas online con tarjetas de crédito y débito, así como también procesamiento de pagos a través del sistema SINPE y además liquidación de fondos de cobro electrónico.',
          workLike: 'Así funciona',
          moreSecurityTitle: 'Más seguridad',
          moreSecurityDescription:
            'Gracias a la tecnología de monitoreo preventivo de Kount, que integra avanzada inteligencia artificial y learning machine, podemos determinar con gran precisión potenciales transacciones fraudulentas y de este modo reducir en un gran porcentaje los intentos de contracargos.',
          requestTypeTitle: 'TIPO DE SOLICITUD',
          requestTypeDescription:
            '¿Necesitas la cuenta bancaria o únicamente los credenciales ecommerce?',
          both: 'Ambos',
          justCredentials: 'Solo credenciales ecommerce',
          generalInformationTitle: 'INFORMACIÓN GENERAL',
          generalInformationDescription:
            'La siguiente información es requerida con fundamento en la Ley 8204 de la República de Costa Rica',
          copnyData: 'Datos de la empresa',
          copnyName: 'Nombre de la empresa',
          comercialActivity: 'Actividad comercial',
          originFunds: 'Origen de fondos',
          constitutionCountry: 'País de constitución',
          representativeData: 'Datos del representante',
          pepTitle: 'PERSONA POLÍTICAMENTE EXPUESTA',
          pepDescription: '¿Es usted una persona políticamente expuesta?',
          legal_docs: 'Documentos legales',
          doc_desc:
            'Por favor adjunta, en formato PDF o imagen PNG, JPG clara, una fotografía por ambos lados de tu documento de identidad.',
          ecommerceTitle: 'CREDENCIALES ECOMMERCE',
          ecommerceDescription:
            'Asociar credenciales para procesamiento de tarjetas crédito y débito a tu cuenta bancaria ya existente',
          idAccount: 'Identificación asociada a cuenta',
          commissions:
            'Las comisiones y tasas contributivas serán asignadas por el banco.',
          auth: 'Declaro tener autorización para realizar esta gestión',
          donwload_app: 'Descarga la app gratis',
          documentEmptyTitle: 'Documento faltante',
          documentEmptyDescription:
            'Debes agregar el documento legal solicitado.',
          successTitle: 'SOLICITUD EXITOSA',
          successDescription:
            'La solicitud se ha enviado con éxito. Un agente de nuestro banco partner se estará comunicando contigo para continuar el proceso.',
        },
        ecommerceCredentials: {
          description:
            'Los credenciales ecommerce son los que te permiten procesar y recibir pago con tarjetas de débito y crédito para la venta de tus productos o servicios a través de los servicios Passer. Las tarifas que acá agregues se cargarán a los usuarios finales como parte del costo por servicios. Te podría interesar ver ',
          getCredentials: '"Cómo obtener mis credenciales"',
          credentialsAdded: 'CREDENCIALES AGREGADOS',
          menu: [
            { icon: 'edit', name: 'Editar' },
            { icon: 'delete', name: 'Eliminar' },
          ],
          addCredentials: 'Agregar credencial',
          infoCredential: 'INFORMACIÓN DEL CREDENCIAL',
          userPasswordTitle: 'USUARIO Y CONTRASEÑA',
          userPasswordDescription:
            'Ingresa el usuario y contraseña para conexión de procesamiento bancario',
          retentionTitle: 'RETENCIONES',
          retentionDescription:
            'Passer Payments realiza la compensación de retención por concepto de tasa bancaria. Esto será agregado a la tarifa final de las ventas online, no aplica para boletería física o pago electrónico.',
          commissionBankPercentage: 'Comisión bancaria (Porcentaje)',
          ivaWithholding: 'Retención IVA (Porcentaje)',
          emptyEditCredential:
            'Presiona "Editar" para consultar y modificar credenciales de ecommerce.',
          emptyEditCredentialSubscription:
            'Presiona "Editar" para consultar y modificar credencial de ecommerce conectada.',
          emptyTitle: 'Sin credenciales ecommerce',
          emptyDescription:
            'Aún no has creado tus credenciales ecommerce. Puedes crear una nueva, o bien, modificar una anterior.',
          deleteFailTitle: 'ACCIÓN NO PERMITIDA',
          deleteFailDescription:
            'Estos credenciales se encuentran conectados a una suscripción. Por favor desconecta primero la suscripción para poder eliminarlos.',
          deleteSuccessDescription: 'Credencial ecommerce eliminada con éxito.',
          updateSuccessDescription:
            'Credencial ecommerce actualizadas con éxito.',
        },
        sinpeCredentials: {
          description:
            'Los credenciales SINPE son los que te permiten procesar y recibir pagos online y de cobro electrónico por medio del Sistema Nacional de Pagos Electrónico directamente desde la app Passer o los validadores de pagor. Te podría interesar ver ',
          addIBANTitle: 'AGREGAR NUEVA CUENTA IBAN',
          addIBANDescription:
            'Si aún no tienes una cuenta bancaria para procesamiento de pago con tarjetas o recaudo de cobro electrónico, utiliza la sección <b>"Nueva cuenta bancaria"</b> para solicitarlos.',
          emptyTitle: 'Sin credenciales SINPE',
          emptyDescription:
            'Aún no has creado tus credenciales SINPE. Puedes crear una nueva en la sección de la derecha.',
          menu: [{ icon: 'delete', name: 'Eliminar' }],
          incorrectCredentialTitle: 'CREDENCIALES INCORRECTOS',
          incorrectCredentialDescription:
            'Lo sentimos, la cuenta IBAN ingresada no ha podido ser encontrada en el sistema bancario nacional, por favor revisa o consulta con tu banco y trata de nuevo.',
        },
        newCredential: {
          title: 'Conexión de nuevos credenciales de procesamiento',
          description:
            'Selecciona una suscripción y luego asigna los credenciales de procesamiento que te permitirán procesar y recibir pagos online o fondos de pago electrónico. Por favor, ',
          view: 'consulta el descargo de responsabilidad aquí',
          pendingSubscription: 'SUSCRIPCIONES PENDIENTE DE ASIGNAR',
          emptyTitle: 'Sin suscripciones',
          emptyDescription:
            'No hay suscripciones pendientes para conexión de credenciales de procesamiento. Puedes crear una nueva, o bien, modificar una anterior',
          credentialConnectionTitle: 'CONEXIÓN DE CREDENCIALES',
          credentialConnectionDescription:
            'Selecciona los credenciales de procesamiento que hayas creado previamente para venta online y recaudo',
          forCardPay: 'PARA PAGOS CON TARJETA',
          forCardPayDescription:
            'Credenciales de procesamiento para recepción de pagos con tarjeta',
          forSinpePay: 'PARA PAGOS A TRAVÉS DE SINPE (Opcional)',
          forSinpePayDescription:
            'Cuenta IBAN para venta online a través de SINPE y recepción de fondos de pago electrónico directamente en el banco de tu preferencia.',
          activeSinpe: 'Activar SINPE para venta online',
          useIban:
            'Utilizar mi cuenta IBAN para recibir los fondos de pago electrónico de SINPE-TP',
          successTitle: 'CONEXIÓN EXITOSA',
          successDescription: 'La empresa ',
          successDescription2:
            ' ha sido correctamente conectada/modificada con los credenciales ingresados',
          selectSubscription:
            'Debes seleccionar la suscripción que deseas conectar.',
        },
      },
      myGroups: {
        title: 'Mis grupos',
        description:
          'Encuentra aquí la información de los diferentes grupos administrativos a los cuales perteneces y tienes acceso. Recuerda, si te das de baja en un grupo siempre mantendrás tu cuenta Passer activa.',
        findGroup: 'Buscar grupos',
        groupOwner: 'Propietario del grupo',
        yourAccess: 'Tu acceso',
        yourConnection: 'Tu conexión',
        idGroup: 'Id del grupo',
        alreadyGroup: 'Ya te encuentras trabajando en dicho grupo',
        unsubscribeDescription:
          'Estás por darte de baja para el grupo administrativo <b>{{group}}</b>.<br/>¿Deséas continuar y darte de baja?',
        menu: [
          { icon: 'switch-account', name: 'Trabajar aquí' },
          { icon: 'person-off', name: 'Darme de baja' },
        ],
        connected: 'Conectado',
        disconnected: 'Desconectado',
      },
    },
    actions: {
      off: 'Apagar',
      on: 'Encender',
      off_on: 'Apagar/Encender',
      open: 'Abrir',
      action: 'Acción',
      actions: 'Acciones',
      assign: 'Asignar',
      successAction: 'Acción exitosa',
      noAction: 'Acción no permitida',
      update: 'Actualizar',
      update_count: 'Actualizar conteo',
      active_deactive: 'Activar/Inactivar',
      deactivate: 'Desactivar',
      change: 'Cambiar',
      change_active: 'Cambiar y activar',
      active: 'Activar',
      modify: 'Modificar',
      mod_data: 'Modificar datos',
      modifySubscription: 'Modificar suscripción',
      inv_Form: 'Formato inválido',
      inv_FormC:
        'Formato inválido. Los formatos permitidos son: PDF, Doc, Docx, JPG, JPEG, PNG',
      search: 'Buscar',
      see: 'Consultar',
      continue: 'Continuar',
      download: 'Descargar',
      send: 'Enviar',
      select_file: 'Selecciona un archivo',
      select_all: 'Seleccionar todos',
      select_allnt: 'Deseleccionar todos',
      add: 'Agregar',
      apply: 'Aplicar',
      accept: 'Aceptar',
      generate: 'Generar',
      not_apply: 'No aplicar',
      exe: 'Ejecutar',
      enable: 'Habilitar',
      disable: 'Inhabilitar',
      save: 'Guardar',
      save_ch: 'Guardar cambios',
      ready: 'Listo',
      next: 'Siguiente',
      previous: 'Anterior',
      mult: 'Multitarifa',
      tarUn: 'Tarifa única',
      colect: 'Colectivo',
      direct: 'Directo',
      new: 'Nuevo',
      close: 'Cerrar',
      cancel: 'Cancelar',
      load: 'Cargar',
      understood: 'Entendido',
      process: 'Procesar',
      filter: 'Filtrar',
      what_is_this: '¿Que es esto?',
      see_benefits: 'Ver beneficios',
      solicit: 'Solicitar',
      confirm: 'Confirm',
      add_route: 'Agregar ruta',
      save_clean: 'Guardar y limpiar',
      edit: 'Editar',
      edit_stretch: 'Editar tramo',
      new_row: 'Nueva fila',
      delete_rows: 'Eliminar filas',
      delete_stretch: 'Eliminar tramo',
      verify: 'Verificar',
      select: 'Selecciona',
      view: 'Ver',
      clean: 'Limpiar',
      delete: 'Eliminar',
      caution: 'Precaución',
      assigning: 'Por asignar',
      way_sel: 'Seleccionar vía',
      seat_sel: 'Seleccionar asientos',
      print: 'Proceder con la impresión',
      donwload: 'Proceder con la descarga',
      road_del: 'Eliminar vía',
      add_fr: 'Agregar fraccionamiento',
      all: 'Todos',
      allF: 'Todas',
      val: 'Validate',
      work_here: 'Trabajar aquí',
      send_Ag: '¿No recibiste el código?',
      refresh: 'Refrescar',
      valid: 'Validar',
      new_copny: 'Nueva empresa',
      verify_asoc: 'Verificar y asociar',
      send_sol: 'Enviar solicitud',
      send_presol: 'Enviar presolicitud',
      send_report: 'Enviar reporte',
      sendCode: 'Enviar código',
      return: 'Atrás',
      save_config: 'Guardar configuración',
      sync: 'Sincronizar',
      move: 'Trasladar',
      goConnect: 'Ver documentación',
      executeProtocol: 'Ejecutar protocolo',
      seeTransactions: 'Ver transacciones',
      applyFilter: 'Aplicar filtro',
      calculate: 'Calcular',
      cancelSubscription: 'Cancelar suscripción',
      recover: 'Recuperar',
      dropOut: 'Darse de baja',
      regenerate: 'Regenerar',
      connect: 'Conectar',
      disconnect: 'Desconectar',
      test: 'Probar',
    },
    alerts: {
      sendErr: 'Error al enviar código',
      incCode: 'Código incorrecto.',
      unknownErr: 'Error desconocido.',
      credentialsTitle: 'VERIFICAR IDENTIDAD',
      credentialsDescription:
        'Por seguridad, vuelve a ingresar tu contraseña de cuenta Passer para mantener la sesión activa',
      anotherSessionTitle: 'Multiples sesiones',
      anotherSessionDescription:
        'Detectamos que tienes varias sesiones abiertas, cerraremos esta sesión y seguirás con la última con la que ingresaste.',
      reSendCode: {
        title: 'Reenvio de código',
        message: 'Hemos Reenviado el código',
      },
      photo: 'Las dimensiones de la imagen deben ser mayores a ',
      photo2: ' pixeles.',
      sel_photo:
        'Debes cargar una imagen antes de poder cambiarla. Si deseas salir del modal sin realizar cambios, presiona el botón "Cerrar"',
      ERRORS: {
        MINLENGTH: 'La longitud mínima de este campo es',
        MAXLENGTH: 'La longitud máxima de este campo es',
        MIN: 'La cantidad mínima de este campo es',
        MAX: 'La cantidad máxima de este campo es',
        ONLYNUMBERS: 'Solo se permiten números',
        REQUIRED: 'Este campo es obligatorio.',
        CHARS: 'caracteres',
        INVALID_FORMAT: 'Formato inválido',
        PASSWORD_VALID:
          'La contraseña debe tener 1 mayúscula, 1 minúscula y un numero',
        PASSWORD_MINLENGTH:
          'La contraseña debe tener un mínimo de 8 caracteres',
        ERROR_CONFIRMPASSWORD: 'Contraseñas no coinciden.',
      },
      sizeImage: 'El tamaño máximo de subida es de 10 Mb',
      cacheErrors: {
        country: 'Error al cargar países.',
        tipeId: 'Error al cargar los tipos de identificación.',
        businessActivity:
          'Error al cargar los tipos de actividades comerciales.',
        positions: 'Error al cargar los tipos de puestos.',
        funds: 'Error al cargar los tipos de fondos.',
        currency: 'Error al cargar los tipos de monedas.',
      },
    },
    datetime: {
      dateTime: 'Fecha/Hora',
      date: 'Fecha',
      hour: 'Hora',
      transactionDate: 'Fecha transacción',
      transactionHour: 'Hora transacción',
      'month/year': 'Mes / Año',
      yearly: 'Anual',
      monthly: 'Mensual',
      registrationDate: 'Fecha registro',
      birthday: 'Fecha nacimiento',
      creationDate: 'Fecha creación',
      rangeDates: 'Rango fechas',
      initHour: 'Hora inicial',
      finHour: 'Hora final',
      constitutionDate: 'Fecha de constitución',
    },
    enterprisesConnect: {
      title: 'ENTERPRISES CONNECT',
      credentials: {
        title: 'CREDENCIALES',
        desc: 'Enterprises Connect te permite aprovechar toda la tecnología e infraestructura Passer en tus propios proyectos y desarrollos.',
        subsAccessTitle: 'SUSCRIPCIONES CON ACCESO A CONNECT',
        subsAccessDescription:
          'Utiliza el token de acceso de cada suscripción para autenticar tu desarrollo. Si la empresa que necesitas conectar no se muestra en la lista es porque no se le creó el tipo de suscripción adecuado. Cambia el tipo de suscripción para poder generar los credenciales para desarrollo.',
        tokenCopy: 'Token copiado',
        emptyTitle: 'Sin suscripciones creadas',
        emptyDescription:
          'No hay suscripciones creadas. Puedes crear una nueva en la opción del menú "Suscripciones" y en el tab de "NUEVA SUSCRIPCIÓN".',
        successRegenerate: 'Tu token se ha regenerado con éxito.',
        noToken:
          'Parece que tu suscripción no tiene un token generado. Puedes utilizar la opción "Regenerar token" para poder asignarle un token a tu suscripción.',
        menu: [
          { icon: 'eye', name: 'Ver/Ocultar token' },
          { icon: 'token', name: 'Regenerar token' },
          { icon: 'content-copy', name: 'Copiar token' },
          { icon: 'code', name: 'Configurar Connect' },
        ],
      },
      configureConnect: {
        title: 'Configurar Enterprises Connect',
        description:
          'Activa Connect para integragar tu propia infraestructura y sistemas y así comenzar el envío de información y procesamiento de datos a través de las APIs Enterprises Connect. Si haces la conexión deberás escribir la lógica de algunas funciones básicas del sistema.',
        acceptRules:
          'Acepto haber leído y comprendido las reglas y guías para creación de funciones. ',
        acceptRules2: 'Consultar reglas',
        workWith: 'TRABAJAR CON CONNECT',
        accessChangeTitle: 'Desactivar Connect',
        accessChangeDescription:
          'Estás por desactivar toda la funcionalidad de Connect. ¿Deséas continuar?',
        changeAccess:
          'La acción se ha realizado con éxito, si tienes consultas puedes comunicarte con soporte Enterprises',
        tabs: [
          {
            name: 'FUNCTIONS CREADAS',
            action: 'functionCreated',
          },
          {
            name: 'NUEVA FUNCTION',
            action: 'newFunction',
          },
        ],
        createdFunctions: {
          emptyListTitle: 'Sin functions',
          emptyListDescription: 'Aún no has programada la lógica de funciones.',
        },
        newFunction: {
          selectFunction: 'Selecciona la función que vas a escribir o editar',
          changesFunction: 'La function se ha creado/modificado con éxito.',
          requiredData:
            'Debes ingresar el código de la functions para poder realizar dicha acción.',
          testFunction:
            'La prueba de la function seleccionada ha sido exitosa.',
        },
      },
      modals: {
        connectTitle: 'ENTERPRISES CONNECT',
        connectDesc:
          'Las APIs de Enterprises Connect te permiten aprovechar y conectar tus propios desarrollos. Es importante que configures las fuctions básicas del sistema. ',
        connectDesc2: 'Conoce más aquí.',
        connectBtn: 'Configurar Connect',
        regenerateTokenTitle: 'REGENERAR TOKEN',
        regenerateTokenDescription:
          'Cuidado, estás por regenerar el token de identificación. Si lo haces se desconectarán los servicios que hayas integrado. Asegúrate de agregar este nuevo token en todas tus integraciones.',
      },
    },
    finance: {
      title: 'PROGRAMA DE FINANCIAMIENTO',
      subTitle: 'Passer + Banco Nacional',
      desc: 'Han unido esfuerzos para la creación de un programa de financiamiento acorde a las nuevas necesidades de transporte público y su modernización. El crédito para pago electrónico se trata de un producto diseñado para adaptarse a las particularidades y requerimientos de la industria, permitiendo a tu empresa poder acceder a las nuevas tecnologías sin tener que realizar fuertes inversiones.ni complicados trámites.',
      tabs: [
        {
          name: 'BENEFICIOS',
          action: 0,
        },
        {
          name: 'CONOCER LOS VALIDADORES',
          action: 1,
        },
      ],
      tabsRight: [
        {
          name: 'REQUISITOS',
          action: 0,
        },
        {
          name: 'CALCULAR CUOTA',
          action: 1,
        },
      ],
      benefit: {
        title: 'Más beneficios',
        desc: 'Disfruta de las mejores condiciones, no solo en pagos, sino en soporte y asesoramiento para que saques el mejor provecho y rendimiento a tu inversión.',
        checks: [
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
        ],
        partners: 'BENEFICIOS PARTNERS',
        box: [
          {
            title: 'Aplica tu cuota de suscripción al pago de tu crédito',
            desc: 'Redireccionaremos la mensualidad/anualidad de tu suscripción Passer a cubrir total o parcialmente el pago de tu crédito.',
          },
          {
            title: 'Tarifa especial validadores',
            desc: 'Recibe hasta un 20% de descuento en el precio base de los validadores, lo que al mismo tiempo reducirá la cuota de tu crédito.',
          },
        ],
      },
      validator: {
        title: 'Validadores EMV/PCI',
        desc: 'Junto con nuestro socio comercial, ',
        desc2:
          ', reconocida compañía europea especializa en soluciones de pago electrónico, hemos integrado a nuestros sistemas de software y componentes backoffice la más moderna y robusta solución de validadores.',
        ppt: 'PPT Excellence',
        equip: 'Equipados con los mejores estándares de la industria',
        equipList: [
          'Certificaciones EMVco L1 y EMV L2',
          'Listo para EMV L3',
          'PCI - PTS 5.1',
          'Inyección de claves remotas, PCI DSS',
          'Puerto SAM Card para interacción bajo ambiente PCI - PTS POI',
          'Todas las certificaciones de la industria automotriz y ferroviarias: FCC - ISED y R&TTE',
        ],
        runwayTitle: 'Pasarela de pagos + Passer/PPT + Banco + Tu empresa',
        runwayDescription:
          'Nuestros validadores y sistemas backoffice están listos para operar e interactuar directamente con tu banco adquirente o bien las pasarelas de pago mediadoras, tal es el caso de LittlePay en Costa Rica. Contacta a tu ejecutivo de cuenta para conocer más sobre nuestro ecosistema de procesamiento.',
        support: 'Con el respaldo y calidad de',
      },
      requirement: {
        title:
          'Seleccionan los documentos que estarás aportando en este presolicitud',
        list: [
          {
            action: false,
            name: 'Fotocopia de cédula de identidad de la persona solicitante',
          },
          { action: false, name: 'Constancia CCSS o INS (una de las dos)' },
          {
            action: false,
            name: 'Copia de las dos últimas declaraciones de renta',
          },
          {
            action: false,
            name: 'Personería Jurídica con Participación Accionaria',
          },
          { action: false, name: 'Personería Jurídica literal' },
          {
            action: false,
            name: 'Estados Financieros de los últimos tres años',
          },
          {
            action: false,
            name: 'Corte financiero con no más de tres meses de antigüedad',
          },
          {
            action: false,
            name: 'Flujo de caja proyectado a un año, expresado de forma mensual (debe de incluir el pago de la deuda, los futuros gastos y los futuros ingresos)',
          },
        ],
      },
      calculate: {
        vehicle: 'Calcular con vehículos de sistema',
        another: 'Otra cantidad',
        totalBase: 'TOTAL BASE DEL CRÉDITO',
        coopenaeCom: 'Comisión Banco Nacional',
        amount: 'Monto desembolso',
        monthlyEst: 'TU CUOTA MENSUAL ESTIMADA SERÍA DE',
        footer:
          'Los montos indicados son un estimado, y no incluyen costos de pólizas y seguros. Un ejecutivo del Banco Nacional te contactará para brindarte más detalles. Enviar esta presolicitud no es una garantía de aprobación, tampoco implica un compromiso para ti.',
      },
    },
    footer: {
      nav: {
        terms: 'Términos de servicios',
        guide: 'Guías de uso',
        support: 'Soporte empresarial',
        app: 'Passer App',
        passer: 'Passer.com',
      },
      login: {
        1: 'Soporte',
        2: 'Condiciones de uso',
        3: 'Desarrolladores',
      },
    },
    global: {
      errorTimeoutTitle: 'Sin respuesta',
      errorTimeoutDescription:
        'Parece que algo anda mal y no hay respuesta del servidor. Nuestras disculpas. Por favor espera y trata en unos minutos.',
      errorMaintenanceTitle: 'En mantenimiento',
      errorMaintenanceDescription:
        'Nuestras disculpas, el servicio está en mantenimiento. Será reestablecido a la mayor brevedad. Trata en unos minutos.',
      errDTSTitle: 'Problemas de conexión',
      errDTSDescription:
        'Lo sentimos, parece que hubo un microcorte de Internet. Cierra esta ventana e intenta de nuevo, si el error persiste contáctanos.',
      erroServerTitle: 'Fallo en obtención de datos',
      erroServerDescription:
        'No ha sido posible obtener la información para tu cuenta, por favor reintenta',
      passer_error_title: 'Ha ocurrido un error',
      passerErrorTitle2: 'Sistema cerrado',
      passer_error_message:
        'Los sentimos, algo ha sucedido y no es posible procesar la solicitud. Por favor intenta de nuevo',
      passerErrorMessage2:
        'Por seguridad el sistema se cerró y deberás ingresar nuevamente.',
      passer_error_cache:
        'Lo sentimos, algo ha sucedido con el cierre de sesión. Por favor intenta de nuevo.',
      passer_idp_error_invalid_grant:
        'Error de inicio de sesion; Correo electrónico o contraseña no válidos.',
      passer_idp_error_invalid_management_group:
        'Lo sentimos, el usuario que inició sesión no tiene acceso a este recurso, intente con otro o comuníquese con su administrador de cuenta de Enterprises.',
      learnMore: 'Puedes conocer más sobre cómo funciona aquí',
      loadCrop: 'Cargar y recortar el logo de tu empresa',
      loadCropWall: 'Cargar y recortar la portada de tu empresa',
      loadImage: 'Cargar imagen',
      logWith: 'Logueado como:',
      inProcessD: 'Esta función del sistema está aún en proceso.',
      noResults: 'No hay registros acorde a los parámetros de búsqueda.',
      managementGroup: 'Grupo administrativo',
      revokeAccess: 'Revocar acceso',
      grantAccess: 'Conceder acceso',
      changeManagementGroup: 'Grupo administrativo cambiado con éxito.',
      deleteManagementGroup: 'Grupo administrativo eliminado con éxito.',
      successActionMessage:
        'Los cambios o ajustes se han generado y/o guardado con éxito.',
    },
    home: {
      title: 'Passer ES',
      chId: 'Cambiar idioma',
      chIdC: 'Cambiar idioma',
      spanish: 'Español',
      english: 'Ingles',
    },
    labels: {
      auth: 'Autorización',
      access: 'Acceso',
      typeAccess: 'Tipo acceso',
      success: 'Completado',
      fail: 'Fallido',
      pending: 'Pendiente',
      email: 'Email',
      pass: 'Contraseña',
      currentPass: 'Contraseña actual',
      newPass: 'Nueva contraseña',
      confirmPass: 'Confirmar contraseña',
      movil_phone: 'Teléfono móvil',
      phoneAccount: 'Teléfono cuenta',
      emailAccount: 'Email cuenta',
      emailUser: 'Email usuario',
      tik: 'Interurbano',
      urb: 'Urbano',
      train: 'Tren',
      name: 'Nombre',
      lastname: 'Apellidos',
      phone: 'Teléfono',
      twoSteps: 'Verificación de 2 pasos',
      pinAccount: 'PIN de cuenta',
      country: 'País',
      bank: 'Banco',
      account: 'Cuenta',
      ibanAccount: 'Cuenta IBAN',
      accountOwner: 'Propietario cuenta',
      owner: 'Propietario',
      iban: 'IBAN',
      type: 'Tipo',
      appPassword: 'AppPassword',
      appName: 'AppName',
      id: 'Identificación',
      type_id: 'Tipo ID',
      typeId: 'Tipo identificación',
      warning: 'Advertencia',
      capacity: 'Aforo',
      warn: 'Advertencia',
      apt: 'Apertura',
      connection: 'Conexión',
      connections: 'Conexiones',
      disconn: 'Desconectado',
      features: 'Características',
      cirr: 'Cierre',
      route: 'Ruta',
      center: 'Centrar',
      desc: 'Descripción',
      road: 'Vía',
      road_Frac: 'Vía o fraccionamiento',
      depPoint: 'Punto de salida',
      arrPoint: 'Punto de llegada',
      price: 'Precio',
      unit: 'Unidad',
      doc: 'Documentación',
      document: 'Documento',
      fracc: 'Fraccionamiento',
      copny: 'Empresa',
      ced: 'Cédula',
      ced_fis: 'Cédula Física',
      ced_jud: 'Cédula Jurídica',
      ced_red: 'Cédula Residencia',
      gob: 'Ente Gobierno',
      ente_aut: 'Ente Autónomo',
      passport: 'Pasaporte',
      origin: 'Origen',
      destination: 'Destino',
      journey: 'Trayecto',
      schedule: 'Horario',
      female: 'Femenino',
      male: 'Masculino',
      other: 'Otro',
      opt_select: 'Opciones seleccionadas',
      days_filter: 'Filtrar días',
      copny_filter: 'Filtrar Empresa',
      perm_filter: 'Filtrar Permisos',
      all_selec: 'Todos seleccionados',
      ms: 'Mensaje',
      photo: 'Foto',
      place: 'Lugar',
      reason: 'Razón',
      num_unit: 'Número de unidad',
      code: 'Código',
      customer_name: 'Nombre completo del cliente',
      driver: 'Chofer',
      route_name: 'Nombre de ruta',
      route_code: 'Código de ruta',
      route_path: 'Buscar trayecto (ruta)',
      yes: 'Si',
      no: 'No',
      print: 'Imprimir',
      year: 'Año',
      number: 'Número',
      act: 'Act',
      inac: 'Inac',
      user: 'Usuario',
      tel_assig: 'Teléfono asignado',
      tick_online: 'Con venta online',
      urb_offline: 'Sin venta online',
      passengers: 'pasajeros',
      votes: 'votos',
      via_tracs: 'Vías / Fraccionamientos',
      window_dig: 'Ventanilla digital',
      boarding: 'Abordaje',
      stair: 'Escaleras',
      numRow: 'Número de fila',
      row: 'Fila',
      moves: 'Movimientos',
      ini_role: 'Inicio role',
      fin_role: 'Fin role',
      tick_off: 'Electrónico',
      tick_on: 'Online',
      reject: 'Rechazos',
      total_rec: 'Total recaudo',
      retention: 'Retención',
      settl: 'Liquidación',
      date_role: 'Fecha role',
      date_liq: 'Fecha liquidación',
      total_pend: 'Total pendiente',
      total_acre: 'Total acreditación',
      vehicle: 'Vehículo',
      amount: 'Monto',
      nationality: 'Nacionalidad',
      canton_dep: 'Cantón/Departamento',
      dist_city: 'Distrito/Ciudad',
      recurrent: 'Recurrente',
      init: 'Inicio',
      end: 'Fin',
      first: 'Primera',
      last: 'Última',
      country_operation: 'País de operación',
      country_birth: 'País de nacimiento',
      country_res: 'País de residencia',
      entity_type: 'Tipo de entidad',
      legal_name: 'Nombre legal',
      business_name: 'Nombre comercial',
      level: 'Nivel',
      bank_cred: 'Credenciales acreditaciones',
      type_device: 'Tipo dispositivo',
      phone_optional: 'Número teléfono (opcional)',
      profOcup: 'Profesión/Ocupación',
      currency_ing: 'Moneda ingreso',
      ing_mnth: 'Ingreso mensual promedio',
      address_ex: 'Dirección exacta',
      exp_id: 'Vencimiento identificación',
      ing_lab: 'Fecha ingreso a laborar',
      ing_pos: 'Fecha ingreso al puesto',
      label: 'Etiqueta',
      rates: 'Tarifas',
      rate: 'Tarifa',
      info: 'Información',
      vehicles: 'Vehículos',
      spaces: 'Espacios',
      more: 'más',
      position: 'Puesto',
      positionCopny: 'Puesto en empresa',
      positionPublic: 'Puesto público',
      date_ing: 'Fecha ingreso',
      subscriptionType: 'Tipo suscripción',
      status: 'Estado',
      plan: 'Plan',
      monthlyRateBase: 'Tarifa base mensual',
      renovation: 'Renovación',
      secretToken: 'Secret token',
      location: 'Ubicación',
      device: 'Dispositivo',
      midEnterprises: 'MID Enterprises',
      moreFilter: 'Más filtros',
      userClient: 'Usuario/Cliente',
      paymentForm: 'Forma pago',
      brand: 'Marca',
      card: 'Tarjeta',
      transactionType: 'Tipo transacción',
      currency: 'Moneda',
      main: 'Titular',
      debts: 'Deudas',
      creationDate: 'Fecha de creación',
      lastUse: 'Último Uso',
      createdAt: 'Creación',
      sex: 'Sexo',
      purchaseQu: 'Cantidad compras',
      quantity: 'Cantidad',
      numeration: 'Numeración',
      shopping: 'Compras',
      holder: 'Titular tarjeta',
      msgAdq: 'Mensaje adquirente',
      issuingBank: 'Banco emisor',
      originCountry: 'País origen tarjeta',
      mid: 'MID',
      term: 'Plazo',
      colons: 'Colones',
      dollars: 'Dólares',
      months: 'meses',
      inProcess: 'En proceso',
      subscriptionGroup: 'Suscripción/Grupo',
      subscription: 'Suscripción',
      connectStatus: 'Estado connect',
      active: 'Activo',
      inactive: 'Inactivo',
      functions: 'Functions',
      function: 'Function',
      modification: 'Modificación',
      platform: 'Plataforma',
      terminal: 'Terminal',
      reference: 'Referencia',
      commissionBank: 'Comisión Banco',
      tax: 'Tributación',
      bankAcquisition: 'Adquirencia banco',
      paymentGateway: 'Pasarela de pagos',
      percentage: 'Porcentual',
      fixed: 'Fija',
      ownerAccount: 'Propietario de grupo',
      accepted: 'Aceptada',
      refused: 'Rechazada',
      reversed: 'Reversada',
      security: 'Seguridad',
      accessLevel: 'Nivel de acceso',
      noAccess: 'Sin acceso',
      version: 'Versión',
    },
    LANGUAGE: {
      TITLE: 'CAMBIAR IDIOMA',
      CHANGE_LAN: 'Cambio de Idioma',
      CHANGE_LAN_Q: '¿Desea cambiar de Idioma?',
    },
    login: {
      sign: 'Iniciar sesión',
      forg_pass: '¿Olvidaste la contraseña?',
      no_account:
        'Si aún no tienes una cuenta Passer Enterprises regístrate gratis para que tu empresa evolucione a lo electrónico y digital.',
      register_copny: 'Registrar mi empresa',
      logOut: 'Cerrar Sesión',
      backLogin: 'Volver al login',
      twoFactor: {
        title: 'AUTENTICACIÓN DE DOS FACTORES',
        description:
          'La autenticación de dos factores requiere que ingreses un código de verificación que fue enviado a tu email o teléfono de cuenta Passer',
        question: '¿Dónde deseas recibir el código de verificación?',
        confirmation: 'Recibirás el código al teléfono terminado en',
        confirmationTwo: 'Recibirás el código al email',
      },
      insertCode: {
        title: 'INGRESAR CÓDIGO',
        description: 'Hemos enviado el código al ',
        descriptionRecover:
          'Por favor ingresa el código que ha llegado a tu email de cuenta Passer',
        question: '¿No recibes el código?',
      },
      deletedAccount: {
        title: 'CUENTA CANCELADA',
        descOne: 'La cuenta para la empresa',
        descOneContinue: 'ha sido suspendida con éxito.',
        descTwo:
          'Si necesitas reingresar puedes hacerlo con tus credenciales de cuenta Passer. En el caso que necesites la reinstalación de validadores u otros dispositivos de hardware por favor comunícate a',
        email: 'enterprises@passer.live',
        descThree: ' o al teléfono ',
        phoneNumber: '+506 4001-4623',
      },
      selectCompany: {
        title: 'SELECCIONA UNA EMPRESA',
        description:
          'Selecciona la empresa con la que deseas comenzar esta sesión',
        suscription_status: {
          0: 'Cancelada',
          1: 'Activa',
          2: 'Pendiente',
          3: 'Suspendida',
        },
      },
      recoverPassword: {
        title: 'RECUPERAR CONTRASEÑA',
        desc: 'Ingresa tu email de cuenta Passer',
        return: 'Volver al login',
      },
      newPassword: {
        title: 'NUEVA CONTRASEÑA',
        desc: 'Crea una nueva contraseña para tu cuenta Passer',
      },
      selectGroup: {
        title: 'SELECCIONA UN GRUPO',
        description:
          'Selecciona el grupo administrativo con la que deseas comenzar esta sesión.',
        owner: 'Dueño:',
        role: 'Tu role:',
      },
    },
    navbar: {
      accountProduct: 'Cuenta Passer',
      suscription: 'Suscripciones',
      accessSecurity: 'Accesos y seguridad',
      enterprisesConnect: 'Enterprises Connect',
      posConnect: 'POS Connect',
      paymentMethod: 'Métodos de pago',
      accountPayments: 'Transacciones',
      guides: 'GUÍAS DE USUARIO',
      openSupportTicket: 'ABRIR UN TICKET DE SOPORTE',
      visitBlog: 'VISITAR EL BLOG',
      programFinance: 'PROGRAMA DE FINANCIAMIENTO',
    },
    notFound: {
      title: 'ALGO EXTRAÑO SUCEDE',
      desc: 'La página que buscas desapareció',
      come_back: 'Regresar al inicio',
    },
    subscription: {
      title: 'SUSCRIPCIONES',
      list: {
        title: 'LISTADO',
        goDashboard: 'Ir al dashboard',
        emptyTitle: 'Sin suscripciones creadas',
        emptyDescription:
          'No hay suscripciones creadas. Puedes crear una nueva en el tab de "NUEVA SUSCRIPCIÓN".',
        menu: [
          { icon: 'link', name: 'Conectar SINPE - TP' },
          { icon: 'link-off', name: 'Desconectar SINPE - TP' },
          { icon: 'description', name: 'Detalles suscripción' },
        ],
      },
      newSubscription: {
        title: 'NUEVA SUSCRIPCIÓN',
        codeTitle: 'CÓDIGO DESCUENTO',
        codeDescription: 'Si tienes un código promocional ingrésalo aquí',
        codeApply: 'Código promocional aplicado con éxito.',
        registerData: {
          loadAvatar: 'Carga la portada de tu empresa',
          copnyTitle: 'INFORMACIÓN DE LA EMPRESA',
          copnyDesc:
            'Agrega la información de la empresa que estará relacionada a esta suscripción.',
          legalRepTitle: 'REPRESENTANTE LEGAL',
          legalRepDescription:
            'Agrega la información de la persona física que representa la empresa.',
          termsTitle: 'TÉRMINOS Y CONDICIONES DE TU SERVICIO',
          termsDescription:
            'Esta información se verá reflejada en el sitio web de Passer para que los usuarios la puedan consultar y conocer tus políticas de uso.',
          change_reemb: 'Políticas sobre cambios y reembolsos',
          sched_serv: 'Políticas sobre horarios de servicios',
          user_at: 'Políticas sobre atención de usuarios',
          other_serv: 'Otras consideraciones sobre los servicios',
          twoRepresentatives: 'Solo puedes agregar dos representantes',
          emptyDataTitle: 'Datos incompletos',
          emptyDataDescription:
            'Asegurate de haber ingresado al menos un representante.',
          noTerms:
            'No tengo políticas propias, utilizar las políticas generales de Passer Enterprises.',
        },
        plans: {
          title: 'Tipo de suscripción y planes',
          subtitle: 'TIPO DE SUSCRIPCIÓN',
          description:
            'Selecciona la opción que mejor consideres se ajusta a tu operación de transporte. Si tienes dudas o consultas contacta a tu ejecutivo de cuenta quien te ayudará en el proceso.',
          types: [
            {
              title: 'OPENING ONLINE',
              boxButtonTitle: 'RECOMENDADO',
              boxTitleBlue: '100% venta online y offline',
              boxDescriptionBlue:
                'Mejora la experiencia de tus servicios al permitir que tus usuarios disfruten siempre el poder reservar sus asientos online de manera 100% garantizada.',
              boxInfoT: 'RECOMENDADO',
              boxInfoD:
                'Ahora hasta un <span class="f18 fw-bold">25%</span> en tu plan en comparación con el tipo Opening',
              enable: true,
              info: [
                'Máxima reducción de hurtos y de los costos asociados al manejo de efectivo',
                '1% de bonificación marginal por cada transacción',
                'Control digital de abordajes, recaudo y liquidaciones',
                'Tu usuarios podrán reservar los servicios de todas tus rutas desde la app',
                'Ventanilla digital 24/7/365 desde la app móvil',
                'Tu usuarios podrán reservar los servicios de todas tus rutas desde la app',
                'Control en tiempo real de recaudo por ruta y vehículo',
                'Acceso a la app Bus Driver',
                'Acceso ilimitado a todo los módulos y mejoras del sistema',
              ],
            },
            {
              title: 'OPENING',
              boxTitleBlue: 'Venta digital flexible',
              boxDescriptionBlue:
                'Decide cuáles de tus servicios podrán los usuarios adquirir de manera digital desde la app.',
              enable: true,
              info: [
                'Buena reducción en los hurtos y de los costos asociados al manejo de efectivo',
                'Control digital de abordajes, recaudo y liquidaciones',
                'Ventanilla digital 24/7/365 desde la app móvil para los servicios que tú elijas',
                'Control en tiempo real de recaudo por ruta y vehículo',
                'Acceso a la app Bus Driver',
                'Acceso ilimitado a todo los módulos y mejoras del sistema',
              ],
            },
            {
              title: 'BASE',
              boxTitleBlue: 'Para comenzar',
              boxDescriptionBlue:
                'Recomendable para que comiences a modernizar tu empresa, tener mejores controles. Inicia una migración paulatina de lo análogo hacia lo digital.',
              enable: true,
              info: [
                'Acceso a todo los módulos básicos, incluido boletería física',
                'Mejora en la eficiencia de tus procesos',
              ],
            },
          ],
          button: 'SUSCRIBIR ESTE PLAN',
          desTitle:
            'Selecciona el plan que mejor se adapte a la operación de la empresa que vas a registrar. Las tarifas varían en función del tipo de suscripción',
          selectOption: 'SELECCIONA UNA OPCIÓN',
          anual: [
            {
              title: 'START',
              topics: [
                {
                  topLabel: 'Mensualidad',
                  value: '$300.00',
                  description: 'USD / mes',
                  extraMessage: 'Cargo mensual a tu tarjeta',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.5%',
                  description: 'Transacción',
                  show: false,
                },
                {
                  topLabel: 'Pago electrónico',
                  value: 'Pronto',
                  extraMessage:
                    'La comisión de pago electrónico aún no entrado en funcionamiento en tu país, así que esto no se te cobrará',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description: 'Agrega hasta 20 vehículos operativos',
                  description1: 'Agrega hasta ',
                  description2: ' vehículos operativos',
                },
              ],
            },
            {
              title: 'MEDIUM',
              topics: [
                {
                  value: '$420.00',
                  description: 'USD / mes',
                  extraMessage: 'Cargo mensual a tu tarjeta',
                  topLabel: 'Mensualidad',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.5%',
                  description: 'Transacción',
                  extraMessage:
                    'Este porcentaje será aplicado cuando realices ventas desde módulo boletería',
                  show: false,
                },
                {
                  topLabel: 'Pago electrónico',
                  value: 'Pronto',
                  extraMessage:
                    'La comisión de pago electrónico aún no entrado en funcionamiento en tu país, así que esto no se te cobrará',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description: 'De 21 a 60 vehículos operativos',
                  description1: 'Agrega hasta ',
                  description2: ' vehículos operativos',
                },
              ],
            },
            {
              title: 'PLUS',
              topics: [
                {
                  topLabel: 'Mensualidad',
                  value: '$915.00',
                  description: 'USD / mes',
                  extraMessage: 'Cargo mensual a tu tarjeta',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.5%',
                  description: 'Transacción',
                  extraMessage:
                    'Este porcentaje será aplicado cuando realices ventas desde módulo boletería',
                  show: false,
                },
                {
                  topLabel: 'Pago electrónico',
                  value: 'Pronto',
                  extraMessage:
                    'La comisión de pago electrónico aún no entrado en funcionamiento en tu país, así que esto no se te cobrará',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description: 'Más de 61 vehículos operativos',
                  description1: 'Más de ',
                  description2: ' vehículos operativos',
                },
              ],
            },
            {
              title: 'PARTNER',
              isSpecial: true,
              topics: [
                {
                  topLabel: 'Registrate hoy',
                  value: 'Únicamente ${{price}} al año',
                  description:
                    'Una forma exclusiva para transformar y maximizar el rendimiento de tu empresa.',
                  show: true,
                },
                {
                  list: true,
                  isSimple: true,
                  title: 'Requisitos',
                  description:
                    'Un beneficio 100% transparente, lo único que debes cumplir para ser partner es',
                  show: true,
                  listOfPoints: [
                    'Agregar, y mantener activos, al menos 10 horarios de servicio por día en un sentido de trayecto.',
                    'Tener una tarifa promedio de $10 o más (o su equivalente en moneda local)',
                    'Mantener al menos un 80% de las rutas creadas activas y con venta online',
                  ],
                },
                {},
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description:
                    'Agrega y opera todos los vehículos que necesites, sin límites ni costos',
                },
              ],
            },
          ],
          monthly: [
            {
              title: 'START',
              topics: [
                {
                  topLabel: 'Mensualidad',
                  value: '$345.00',
                  description: 'USD / mes',
                  extraMessage: 'Cargo mensual a tu tarjeta',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.7%',
                  description: 'Transacción',
                  show: false,
                },
                {
                  topLabel: 'Pago electrónico',
                  value: 'Pronto',
                  extraMessage:
                    'La comisión de pago electrónico aún no entrado en funcionamiento en tu país, así que esto no se te cobrará',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description: 'Agrega hasta 20 vehículos operativos',
                  description1: 'Agrega hasta ',
                  description2: ' vehículos operativos',
                },
              ],
            },
            {
              title: 'MEDIUM',
              topics: [
                {
                  value: '$483.00',
                  description: 'USD / mes',
                  extraMessage: 'Cargo mensual a tu tarjeta',
                  topLabel: 'Mensualidad',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.7%',
                  description: 'Transacción',
                  extraMessage:
                    'Este porcentaje será aplicado cuando realices ventas desde módulo boletería',
                  show: false,
                },
                {
                  topLabel: 'Pago electrónico',
                  value: 'Pronto',
                  extraMessage:
                    'La comisión de pago electrónico aún no entrado en funcionamiento en tu país, así que esto no se te cobrará',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description: 'De 21 a 60 vehículos operativos',
                  description1: 'Agrega hasta ',
                  description2: ' vehículos operativos',
                },
              ],
            },
            {
              title: 'PLUS',
              topics: [
                {
                  topLabel: 'Mensualidad',
                  value: '$1.053.00',
                  description: 'USD / mes',
                  extraMessage: 'Cargo mensual a tu tarjeta',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.7%',
                  description: 'Transacción',
                  extraMessage:
                    'Este porcentaje será aplicado cuando realices ventas desde módulo boletería',
                  show: false,
                },
                {
                  topLabel: 'Pago electrónico',
                  value: 'Pronto',
                  extraMessage:
                    'La comisión de pago electrónico aún no entrado en funcionamiento en tu país, así que esto no se te cobrará',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description: 'Más de 61 vehículos operativos',
                  description1: 'Más de ',
                  description2: ' vehículos operativos',
                },
              ],
            },
            {
              title: 'PARTNER',
              isSpecial: true,
              topics: [
                {
                  topLabel: 'Registrate hoy',
                  value: 'Únicamente ${{price}} al mes',
                  description:
                    'Una forma exclusiva para transformar y maximizar el rendimiento de tu empresa.',
                  show: true,
                },
                {
                  list: true,
                  isSimple: true,
                  title: 'Requisitos',
                  description:
                    'Un beneficio 100% transparente, lo único que debes cumplir para ser partner es',
                  show: true,
                  listOfPoints: [
                    'Agregar, y mantener activos, al menos 10 horarios de servicio por día en un sentido de trayecto.',
                    'Tener una tarifa promedio de $10 o más (o su equivalente en moneda local)',
                    'Mantener al menos un 80% de las rutas creadas activas y con venta online',
                  ],
                },
                {},
                {
                  isSimple: true,
                  value: 'Vehículos',
                  description:
                    'Agrega y opera todos los vehículos que necesites, sin límites ni costos',
                },
              ],
            },
          ],
          subscriptionSelect:
            'Debes seleccionar un tipo de suscripción antes de suscribir un plan.',
          choosePlan: 'ESCOGER PLAN',
          back: 'VOLVER A TIPOS',
          register: 'REGISTRAR SUSCRIPCIÓN',
        },
      },
      remindSubscription: {
        title: 'AGREGA UNA SUSCRIPCIÓN',
        desc: 'Registra y configura tu primera suscripción de empresa. De este modo podrás empezar a crear servicios, vender online y mucho más.',
        add: 'Agregar ahora',
      },
      modals: {
        subscriptionCreatedTitle: 'SUSCRIPCIÓN GENERADA CON ÉXITO',
        subscriptionCreatedDescription1: 'Tu suscripción tipo <b>',
        subscriptionCreatedDescription2: '</b> con el plan <b>',
        subscriptionCreatedDescription3: '</b> ha sido registrada con éxito. ',
        subscriptionCreatedDescription4:
          'Ya puedes comenzar a crear servicios y administrar tu operación.',
        subscriptionCreatedDescription5:
          'Un ejecutivo de cuenta se pondrá en contacto en un plazo máximo de 48 hora hábiles para colaborarte.',
        subscriptionChangeTitle: 'CAMBIO EXITOSO',
        subscriptionChangeDescription:
          'La modalidad de tu suscripción ha sido cambiada con éxito.',
        sinpeTitle: 'SINPE-TP',
        sinpeDescription:
          'Trabajamos para que pronto te puedas conectar al sistema de recaudo central del BCCR. Para mayor información puedes comunicarte con nosotros enterprises@passer.live',
      },
      detail: {
        title: 'Detalles de la suscrpción',
        info: 'INFORMACIÓN REGISTRAL',
        canUpdate:
          'Puedes modificar los datos registrales y otra información desde la sección "Empresa" del dashboard de la suscripción.',
        cancelTitle: 'Cancelar suscripción',
        cancelDescription:
          'Estás por cancelar la suscripción <b>{{subs}}</b>. ¿Deséas continuar?',
        updateTitle: 'Modificar suscripción',
        updateDescription:
          'Estás por modificar la suscripción <b>{{subs}}</b>. ¿Deséas continuar?',
      },
      status: {
        inactive: 'Inactiva',
        active: 'Activa',
        pending: 'Pendiente',
        suspended: 'Suspendida',
        canceled: 'Cancelada',
        list: ['Activa', 'Suspendida', 'Cancelada'],
      },
    },
    suspendedAccess: {
      title: 'ACCESO SUSPENDIDO',
      description:
        'Un propietario o administrador de grupos ha revocado tu acceso a esta suscripción o cuenta. Por favor intenta ingresar con otro usuario o comunícate con la administración de tu empresa.',
      return: 'Regresar al login',
    },
    payment_method: {
      title: 'Métodos de pago',
      aso_cardsTitle: 'Tarjetas asociadas',
      aso_cardsDescription:
        'Debes tener al menos un método de pago activo para mantener tu suscripción.',
      make_princ: 'Hacer principal',
      principal: 'Principal',
      new_meth: 'Nuevo método de pago',
      holder: 'Nombre de titular de la tarjeta',
      card_number: 'Numeración tarjeta',
      exp_seg: 'Expiración y seguridad',
      history_pay: 'Historial de pagos',
      period: 'Periodo',
      per_coll: 'Periodo',
      paym_meth: 'Método de pago',
      pay: 'Pagar',
      success: 'Completado',
      fail: 'Fallido',
      pending: 'Pendiente',
      alert_susp:
        'El acceso a esta empresa ha sido suspendido por falta de pago. Por favor actualiza los datos de tu tarjeta y reintenta.',
      err_load: 'Error cargando tarjetas',
      err_load_history: 'Error cargando historial de pagos',
      successCreate: 'Método de pago creado con éxito.',
      successUpdate: 'Método de pago principal actualizado con éxito.',
      successDelete: 'Método de pago eliminado con éxito.',
      warningPayTitle: 'Realizar pago',
      warningPayDescription:
        'Estas por realizar el pago de la suscripción <b>{{subs}}</b>, del periodo <b>{{period}}</b>. ¿Deséas continuar?',
    },
  },
};
