import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, TimeoutError } from 'rxjs';
import {
  retry,
  catchError,
  switchMap,
  filter,
  take,
  takeWhile,
} from 'rxjs/operators';
import { RequestErrors } from '@enums/requesterrors.enum';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { IdPResponseError } from '@enums/idpresponseerrors.enum';
import { Router } from '@angular/router';
import { ROUTES_ADMIN } from '@constants/routes';
import { oneRetryReqs } from '@data/newtork.data';
import { Platform } from '@ionic/angular';
import { IdPAccessTokenResponse } from '@models/responses/response-login';
import { TranslationService } from '@providers/translation-new/translation.service';
import { LoginService } from '@endpoints/login.service';
import { SharedService } from '@providers/shared/shared.service';
import { StorageService } from '@providers/storage/storage.service';
import { StorageKeys } from '@enums/storage-keys.enum';
import { ModalService } from '@providers/modal/modal.service';
import { ConfirmCredentialsComponent } from '@components/confirm-credentials/confirm-credentials.component';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';

const MAX_RETRIES = 2;
const MIN_RETRIES_WEB = 0;
const MIN_RETRIES_MOBILE = 1;

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<unknown> =
    new BehaviorSubject<unknown>(null);

  constructor(
    private loginService: LoginService,
    private methodGenerals: MethodsGeneralService,
    private modalService: ModalService,
    private platform: Platform,
    private router: Router,
    private sessionData: SessiondataService,
    private sharedService: SharedService,
    private storage: StorageService,
    private translation: TranslationService
  ) {}

  private setRetries(url, method): number {
    const isOneTry = Object.entries(oneRetryReqs).some(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => url.includes(value) && method !== 'GET'
    );
    if (!isOneTry) {
      return MAX_RETRIES;
    }
    // TODO - REVIEW IN A MOBILE WEB
    return this.platform.is('mobile') ? MIN_RETRIES_MOBILE : MIN_RETRIES_WEB;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const handleRequest = next.handle(request).pipe(
      retry(this.setRetries(request.url, request.method)),
      catchError((errorHttp: HttpErrorResponse) => {
        const global = this.translation.getLangMessagesByKeys('global');
        const { status, error } = errorHttp;

        if (errorHttp instanceof TimeoutError) {
          throw null;
        }

        if (status === RequestErrors.Unknown) {
          const statusConnection = window.navigator.onLine;
          if (!statusConnection) {
            const { errDTSTitle, errDTSDescription } = global;
            this.sharedService.showSnackBar(errDTSTitle, errDTSDescription);
            throw null;
          } else {
            const { erroServerTitle, erroServerDescription } = global;
            this.sharedService.showSnackBar(
              erroServerTitle,
              erroServerDescription
            );
            throw null;
          }
        } else {
          switch (status) {
            case RequestErrors.Unauthorized: {
              return this.handle401Error(request, next);
            }
            case RequestErrors.Forbidden: {
              this.router.navigate([ROUTES_ADMIN.SUSPENDED_ACCESS], {
                replaceUrl: true,
              });
              throw null;
            }
            case RequestErrors.ServiceUnavailable:
            case RequestErrors.BadGateway:
              throw {
                title: global.errorMaintenanceTitle,
                message: global.errorMaintenanceDescription,
              };
            case RequestErrors.GatewayTimeout:
              throw {
                title: global.errorTimeoutTitle,
                message: global.errorTimeoutDescription,
              };
            case RequestErrors.NotFound:
              throw {
                title: global.passer_error_title,
                message: global.passer_error_message,
              };
            case RequestErrors.BadRequest: {
              const {
                passer_error_title: title,
                passer_error_message: message,
              } = global;
              if (error?.error) {
                const {
                  passer_idp_error_invalid_grant,
                  passer_idp_error_invalid_management_group,
                } = global;
                switch (error?.error) {
                  case IdPResponseError.InvalidGrant:
                    throw {
                      title,
                      message: passer_idp_error_invalid_grant,
                      data: error?.error,
                    };
                  case IdPResponseError.InvalidManagementGroup:
                    throw {
                      title,
                      message: passer_idp_error_invalid_management_group,
                      data: error?.error,
                    };
                }
              }
              const response = {
                title: error?.message || title,
                message: error?.description || message,
              };
              throw response;
            }
            default: {
              const {
                passer_error_title: title,
                passer_error_message: message,
              } = global;
              const response = {
                title: error?.message || title,
                message: error?.description || message,
              };
              throw response;
            }
          }
        }
      })
    );

    return handleRequest.pipe(
      takeWhile(() => {
        return window.navigator.onLine;
      })
    ) as Observable<HttpEvent<unknown>>;
  }

  private handle401Error(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.sessionData.accessToken;
      const activeManagementGroupId = this.sessionData.activeManagementGroupId;

      if (token) {
        return this.loginService
          .refreshToken(token.refresh_token, activeManagementGroupId as string)
          .pipe(
            switchMap((tokenResponse: IdPAccessTokenResponse) => {
              this.isRefreshing = false;

              this.sessionData.accessToken = tokenResponse;
              this.storage.setData(
                StorageKeys.TOKEN,
                this.sessionData.accessToken
              );
              this.refreshTokenSubject.next(tokenResponse);

              return this.success401Return(request, next);
            }),
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            catchError(_ => {
              return this.modalService
                .showAlertCompReturnObs(ConfirmCredentialsComponent, {})
                .pipe(
                  switchMap((reLogin: boolean) => {
                    if (reLogin) {
                      this.isRefreshing = false;
                      return this.success401Return(request, next);
                    } else {
                      this.loginService.unauthorized = true;
                      return this.logOutAction();
                    }
                  })
                );
            })
          );
      } else {
        return this.logOutAction();
      }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(() => this.success401Return(request, next))
    );
  }

  success401Return(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { accessToken, idpSub, activeManagementGroupId } = this.sessionData;
    const { currentlanguage } = this.translation;
    return next.handle(
      this.methodGenerals.getHeaders(
        request,
        accessToken,
        currentlanguage,
        idpSub,
        activeManagementGroupId
      )
    );
  }

  logOutAction(): Observable<HttpEvent<unknown>> {
    this.loginService.logoutMethod(true, true);
    this.isRefreshing = false;
    throw null;
  }
}
