export enum EndpointNames {
  payBilling = '/api/billing',
  bankCardRegister = '/api/paymentmethods',
  createSubscription = '/api/company/subscription',
  cancelSubscription = '/api/subscriptions',
}

export enum LoginEndpoints {
  loginToken = 'idp.passer.live/token',
  userInfo = 'idp.passer.live/me',
  idpRequest = 'idp.passer.live/api/providers/Passer.',
  requestOTP = 'deaapi.passer.live/api/auth/otp',
  verifyOtp = 'deaapi.passer.live/api/auth/verify-otp',
  VerifyResetOTP = 'deaapi.passer.live/api/auth/verify-reset-otp',
}

export enum FfStatus {
  loading = 'loading',
  success = 'succes',
  error = 'error',
}
