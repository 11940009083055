import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageKeys } from '@enums/storage-keys.enum';
import { StorageService } from '@providers/storage/storage.service';
import { ROUTES_ADMIN } from '@constants/routes';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { LoginService } from '@endpoints/login.service';
import { FeatureFlagsService } from '@providers/feature-flags/feature-flags.service';

export const authGuard = async (route: ActivatedRouteSnapshot) => {
  const router: Router = inject(Router);
  const storage: StorageService = inject(StorageService);
  const session: SessiondataService = inject(SessiondataService);
  const userManager: UserManagerService = inject(UserManagerService);
  const loginService: LoginService = inject(LoginService);
  const featureFlags: FeatureFlagsService = inject(FeatureFlagsService);

  const isLoggedIn: boolean = await storage.getData(StorageKeys.IS_LOGGED_IN);
  await featureFlags.init();
  const path = route.routeConfig.path;
  const isPathLogin = path == 'login';
  const logout = route.queryParamMap.get('logout') === 'true';

  switch (true) {
    // Caso #2: Usuario no está logueado, pero carga login -> true
    case !isLoggedIn && isPathLogin:
      return true;

    // Caso #3/4: Usuario logueado, cargando home o login sin logout -> autoLogin() -> true si es home, false si es login
    case isLoggedIn && !logout:
      autoLogin(storage, session, userManager);
      if (isPathLogin) {
        router.navigate([ROUTES_ADMIN.ROOT_ROUTE], { replaceUrl: true });
      }
      return !isPathLogin;

    // Caso #6/5: Usuario logueado, cargando login o home con logout -> dispatch(logOut) -> true si es login y false si no
    case isLoggedIn && logout:
      loginService.logoutMethod(true, true);
      return isPathLogin;

    // Caso #1/Default: Usuario no está logueado, pero está cargando home -> false -> /login
    default:
      if (!isPathLogin) {
        router.navigate([ROUTES_ADMIN.LOGIN_ROUTE], {
          replaceUrl: true,
        });
      }
      return false;
  }
};

const autoLogin = async (
  storage: StorageService,
  session: SessiondataService,
  userManager: UserManagerService
) => {
  const [
    user,
    isLoggedIn,
    token,
    roleAssignment,
    activeManagementGroup,
    managementGroups,
  ]: any = await Promise.all([
    storage.getData(StorageKeys.USER),
    storage.getData(StorageKeys.IS_LOGGED_IN),
    storage.getData(StorageKeys.TOKEN),
    storage.getData(StorageKeys.ROLE_ASSIGNMENT),
    storage.getData(StorageKeys.ACTIVE_MANAGEMENT_GROUP),
    storage.getData(StorageKeys.MANAGEMENT_GROUPS),
  ]);
  session.isLoggedIn = isLoggedIn ?? false;
  session.accessToken = token;
  session.activeManagementGroupId = activeManagementGroup;
  session.managementGroups = managementGroups;
  session.idpSub = user?.sub;

  userManager.level = roleAssignment?.roleDefinition?.roleName.toLowerCase();
  userManager.levelName = roleAssignment?.roleDefinition?.displayName || '-';
  userManager.currentUser = user;
};
