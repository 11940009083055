export enum StorageKeys {
  TOKEN = 'USR_TOKEN',
  LANG = 'LANGUAGE',
  DARK_MODE = 'DARK_MODE',
  IS_LOGGED_IN = 'IS_LOGGED_IN',
  MISSING_SUBSCRIPTION = 'MISSING_SUBSCRIPTION',
  USER = 'CURRENT_USER',
  MANAGEMENT_GROUPS = 'MANAGEMENT_GROUPS',
  ACTIVE_MANAGEMENT_GROUP = 'ACTIVE_MANAGEMENT_GROUP',
  ROLE_ASSIGNMENT = 'ROLE_ASSIGNMENT',
  subscriptionSelected = 'subsSelected',
  retryLogin = 'retryLogin',
}
