export const enLocale = {
  language: 'en',
  data: {
    accessSecurity: {
      title: 'ACCESS AND SECURITY',
      accessRegister: {
        title: 'ACCESS RECORD',
        securityProt: 'Security protocols',
        securityProtOptions: [
          { act: 1, name: 'Log out of the selected user' },
          { act: 2, name: 'Close all sessions' },
          {
            act: 3,
            name: 'Close all sessions and require two-factor authentication',
          },
          {
            act: 4,
            name: 'Log out of selected user and require two-factor authentication',
          },
        ],
        menu: [
          { icon: 'check-box', name: 'Manage access' },
          { icon: 'pending-actions', name: 'History of actions' },
          { icon: 'person-off', name: 'Unsubscribe' },
        ],
        actionSuccessTitle: 'ACTION EXECUTED',
        actionSuccessDescription:
          'The action has been carried out successfully, if you have questions you can contact Enterprises support',
        userEmptyTitle: 'Actions not found',
        userEmptyDescription: 'This user has no registered actions yet.',
        companyDataNotFound:
          'Sorry, the company information could not be obtained.',
        checkUser:
          'You must first select the desired user, to proceed with this action.',
        noUnsubscribe:
          'This action is not allowed because your role is of lower rank than the user you want to unsubscribe.',
        unsubscribeTitle: 'Unsubscribe',
        unsubscribeDescription:
          'You are about to unsubscribe user <b>{{user}}</b>.<br/>Do you want to continue?',
        unsubscribe: 'User was successfully unsubscribed.',
      },
      newAccess: {
        title: 'NEW ACCESS',
        subTitle: 'ENTERPRISE ADMINISTRATIVE ACCESSES',
        desc: 'They allow you to create and modify subscriptions, as well as add or delete bank credentials, among other administrative functions.',
        accountList: [
          {
            title: 'ACCOUNT OWNER',
            desc: 'It has access to all the resources, subscriptions, platforms and functions of the administrative group to which it belongs.',
          },
          {
            title: 'ACCOUNT MANAGER',
            desc: 'Have access to: Account Module (not to products Payments) - Subscriptions (not to new subscription) - Enterprises Connect - Account Payments - User Guidelines, Sopport Tickets and Blog.',
          },
          {
            title: 'DEVELOPER',
            desc: 'Have access to: Account Module (not to products Payments) - Enterprises Connect - User guidelines, Sopport Tickets and Blog.',
          },
        ],
        subscriptionTitle: 'SUBSCRIPTION ACCESSES',
        subscriptionDescription:
          'Access levels for users who will be working specifically in one of the business subscriptions. They do not have permission to enter the administrative modules.',
        subscriptionList: [
          {
            title: 'SUBSCRIPTION ADMINISTRATOR',
            desc: 'Has access to all modules and functions of the assigned subscription.',
          },
          {
            title: 'SELLER',
            desc: 'It has default access to the modules: Ticketing and Monitoring.',
            privileges: [
              { idModule: 2, name: 'Drivers', act: false },
              { idModule: 3, name: 'Roles', act: false },
              { idModule: 5, name: 'Route maintenance', act: false },
              { idModule: 6, name: 'Vehicle registration', act: false },
              { idModule: 8, name: 'Collection and settlements', act: false },
              { idModule: 9, name: 'Locations', act: false },
              { idModule: 40, name: 'Logistic', act: false },
            ],
          },
          {
            title: 'ENROLLING/OPERATIVE',
            desc: 'It has default access to the Roles, Drivers and Monitoring modules.',
            privileges: [
              { idModule: 4, name: 'Ticket office', act: false },
              { idModule: 5, name: 'Route maintenance', act: false },
              { idModule: 6, name: 'Vehicle registration', act: false },
              { idModule: 8, name: 'Collection and settlements', act: false },
              { idModule: 9, name: 'Locations', act: false },
              { idModule: 40, name: 'Logistic', act: false },
            ],
          },
          {
            title: 'ANALYST',
            desc: 'Have access to: Profile - Vechile Monitoring - Statistics - Collection and Banks (but only collect in progress).',
          },
          {
            title: 'MECHANIC',
            desc: 'Have access to: Profile - Vechicle Monitoring - Mechanic - Vechicle Registration - Roles (not to create new role).',
          },
        ],
        rifhtDesc:
          'Grant access to members of your organization to collaborate with you on account management or subscriptions.',
        accountAccess: 'Administrative access',
        subscriptionAccess: 'Subscription access',
        accessSuccessTitle: 'ACCESS SENT SUCCESSFULLY',
        accessSuccessDescription: 'You have sent an access invitation to ',
        accessSuccessDescription2: ' with permissions from ',
        userExistTitle: 'USER ALREADY EXISTS',
        userExistDescription: 'The email ',
        userExistDescription2:
          ' seems that already exist as an account user, please check and try again.',
      },
      modal: {
        title: 'ACCESS CONTROL',
        currentAccess: 'Current access',
        newAccess: 'New access',
      },
      accessManagement: {
        warningIsOwner:
          'This user is of type <b>"Owner"</b>, which means that they have universal access to all resources, platforms, and functions of the administrative group. You cannot downlevel this user.',
        accessSubscriptionTitle: 'ACCESS SUBSCRIPTIONS',
        warningDES:
          'Set the access level on each subscription you want to assign.',
        changeTitle: 'GRANT ACCESS',
        changeDescription:
          'You are about to grant new system access for this user. Do you want to continue?',
        revokeTitle: 'REVOKE ACCESS',
        revokeDescription:
          'You are about to remove system access for this user. Do you want to continue?',
        accessDeleteSuccess: 'Access successfully revoked.',
        accessChangeSuccess: 'Access successfully granted.',
        descriptionRoleDEA: [
          {
            role: 20,
            description:
              'Has access to all resources, subscriptions, platforms, and group functions of the administrative group to which you belong.',
          },
          {
            role: 21,
            description:
              'Has access to all administrative group resources and functions for the assigned group. You cannot register or modify subscriptions.',
          },
          {
            role: 22,
            description:
              'Has access to Enterprises Connect and its development features. You do not have access to subscriptions.',
          },
        ],
        descriptionRoleDES: [
          {
            role: 1,
            description:
              'Has access to all the modules and functions of the assigned subscription.',
          },
          {
            role: 2,
            description:
              'Access to the ticket office modules, locations, monitoring and support.',
          },
          {
            role: 3,
            description:
              'Access to the modules of roles, drivers, locations, vehicle registration and monitoring and support.',
          },
          {
            role: 4,
            description:
              'Access to the statistics and monitoring and support modules.',
          },
          {
            role: 5,
            description:
              'Access to the role, mechanic, vehicle registration and monitoring and support modules.',
          },
        ],
        managementMenu: [{ icon: 'person-remove', name: 'Revoke access' }],
      },
    },
    accountPayment: {
      title: 'ACCOUNT PAYMENTS',
      desc: 'Transactional monitoring and control system powered by Passer Payments technology and gateway systems.',
      emptyDescription:
        'There are no subscriptions created. You can create a new one in the menu option "Subscriptions" in the "NEW SUBSCRIPTION" tab.',
      noSelection:
        'You must first select a subscription to view its transactions.',
      transaction: {
        tabs: [
          {
            name: 'TRANSACTIONS',
            action: 'transaction',
          },
          {
            name: 'CUSTOMERS',
            action: 'client',
          },
          {
            name: 'REGISTERED PAYMENT METHODS',
            action: 'register-payment',
          },
        ],
        menu: [
          {
            icon: 'description',
            name: 'Details',
          },
        ],
        sixDigits: 'First 6 digits',
        fourDigits: 'Last 4 digits',
        otherTransaction: 'Other related transactions',
        errorRefund:
          'The transaction must be in <b>Approved</b> status in order to perform a refund.',
      },
      client: {
        menu: [{ icon: 'DEFAULT_PAYMENT', name: 'Associated cards' }],
        paymentModals: 'ASSOCIATED PAYMENT METHODS',
        noPaymentMethodTitle: 'No payment methods',
        noPaymentMethodDescription:
          'This user has no associated payment methods.',
        status: {
          inactive: 'Inactive',
          active: 'Active',
          locked: 'Locked by user',
          sysLocked: 'Locked by system',
          main: 'Principal',
        },
      },
      status: {
        approved: 'Approved',
        declined: 'Declined',
        refunded: 'Refunded',
        none: 'N/A',
        pending: 'Pending',
      },
    },
    accountProduct: {
      account: {
        title: 'Passer account',
        desc: 'Your Passer account is a universal access that you can use to access all Passer platforms and their functions.<br><br>When you modify the account data they will be modified in a general way, so make sure you remember them and carry out the corresponding verifications.',
        paymentTitle: 'Payments Products',
        paymentDescription:
          'If you still do not have a business bank account, you can enter "Passer Payments" and request your Banco Nacional account or your ecommerce credentials.',
        credentialsTitle: 'ACCESS CREDENTIALS',
        credentialsDescription:
          'If you change your credentials press "Send code" to receive a code via SMS on your mobile device or email account.',
        pinDesc:
          'You will use the account PIN in all your transactions and purchases of the Passer services.',
        accountUpdate: 'Passer account updated successfully.',
        twoSteps:
          'Enable or disable two-step verification for your Passer account. We recommend having this option enabled for added security.',
      },
      product: {
        title: 'Passer payment',
        tabs: [
          {
            name: 'NEW BANK ACCOUNT',
            action: 'newAccount',
          },
          {
            name: 'CONNECTED SUBSCRIPTIONS',
            action: 'connectedSubscriptions',
          },
          {
            name: 'ECOMMERCE CREDENTIALS',
            action: 'ecommerceCredentials',
          },
          {
            name: 'SINPE CREDENTIALS',
            action: 'sinpeCredentials',
          },
        ],
        connectedSubscriptions: {
          title: 'Connected subscriptions',
          description:
            'Connected subscriptions already have online processing or electronic payment credentials assigned. Please, ',
          view: 'see the disclaimer here.',
          menu: [
            { icon: 'edit', name: 'Edit' },
            { icon: 'credit-card-off', name: 'Disconnect card payments' },
            { icon: 'account-balance', name: 'Disconnect SINPE' },
          ],
          connectSubscription: 'Connect subscription',
          connectedCredentialsTitle: 'CONNECTED CREDENTIALS',
          connectedCredentialsDescription:
            'Credentials currently connected to the selected subscription',
          payCardTitle: 'CARD PAYMENTS',
          payCardDescription: 'Credentials to receive card payments',
          sinpePayTitle: 'PAYMENTS VIA SINPE',
          sinpePayDescription:
            'Credentials to receive payments through the SINPE system',
          sinpeActivated: 'SINPE was activated for online sales',
          ibanActivated:
            'The IBAN account was activated for liquidation of electronic payment funds',
          modifyTitle: 'MODIFY CREDENTIALS',
          modifyDescription:
            'When you modify the credentials all the funds will be redirected to the associated bank accounts',
          emptyTitle: 'No subscriptions connected',
          emptyDescription:
            'There are no subscriptions connected. You can create a new one or modify an old one.',
          ibanAccount: 'IBAN Account (SINPE)',
          bank: 'Bank (SINPE)',
          owner: 'Account owner (SINPE)',
          warningTitle: 'WARNING',
          warningDescription:
            'You are about to disconnect the processing credentials for receiving card payments. If you prefer, you can modify the credentials <b>Do you want to continue?</b>',
          warningSinpeDescription:
            'You are about to disconnect the processing credentials for receiving payments through SINPE. If you prefer, you can modify the credentials <b>Do you want to continue?</b>',
          warnSubscriptionTypeTitle: 'WARNING - SUBSCRIPTION TYPE',
          warnSubscriptionTypeDescription:
            'This subscription is of the Opening Online type, for which it is required to have associated online processing credentials. You can change the type of subscription or modify the credentials.',
          disconnectCredentials:
            'The processing credentials for receiving card payments have been successfully disconnected.',
        },
        modals: {
          newCredential: {
            title: 'ADD NEW CREDENTIAL',
            desc: 'If you still do not have credentials for card payment processing, use the <b>"New bank account"</b> section to request them.',
            incorrectCedentialTitle: 'INCORRECT CREDENTIALS',
            incorrectCedentialDescription:
              'Sorry, the credentials you entered seem to be incorrect, please check and try again.',
            owner:
              'The account credentials entered are associated with the email ',
            owner2:
              '. Please make sure this is the email that was registered to your account with your bank.',
            agree:
              'I agree to associate the ecommerce credentials associated with the indicated email, as well as the terminal ID and bank affiliate.',
            appNameTooltip: 'Username that the bank has assigned you.',
            appPasswordTooltip:
              'Password associated with your AppName. Please never reveal this information.',
            credntialsSuccess: 'New bank credential created successfully',
            validCredentials: 'You must validate your credentials to continue',
            terminalID: 'Terminal ID',
            terminalTool:
              'Identifier of the electronic commerce terminal that the bank has assigned to you.',
            bankAffiliate: 'Bank Affiliate',
            bankAffiliateTool:
              'Customer identification code that you have before the bank.',
          },
        },
        newAccount: {
          title: 'Manage bank account opening and credentials for ecommerce',
          desc: 'In a simple and direct way you can make the request to open the bank account for your company as well as the credentials for ecommerce. This will allow you to connect your subscriptions to process online sales with credit and debit cards, as well as payment processing through the SINPE system and also liquidation of electronic collection funds.',
          workLike: 'That is how it works',
          moreSecurityTitle: 'More security',
          moreSecurityDescription:
            'Thanks to Kount preventive monitoring technology, which integrates advanced artificial intelligence and machine learning, we can determine potential fraudulent transactions with great precision and thus reduce chargeback attempts by a large percentage.',
          requestTypeTitle: 'TYPE OF REQUEST',
          requestTypeDescription:
            'Do you need the bank account or just the ecommerce credentials?',
          both: 'Both of them',
          justCredentials: 'Only ecommerce credentials',
          generalInformationTitle: 'GENERAL INFORMATION',
          generalInformationDescription:
            'The following information is required based on Law 8204 of the Republic of Costa Rica',
          copnyData: 'Company data',
          copnyName: 'Company name',
          comercialActivity: 'Comercial activity',
          originFunds: 'Funds origin',
          constitutionCountry: 'Constitution country',
          representativeData: 'Representative details',
          pepTitle: 'POLITICALLY EXPOSED PERSON',
          pepDescription: 'Are you a politically exposed person?',
          legal_docs: 'Legal documents',
          doc_desc:
            'Please attach, in PDF format or PNG image, clear JPG, a photograph on both sides of your identity document.',
          ecommerceTitle: 'ECOMMERCE CREDENTIALS',
          ecommerceDescription:
            'Associate credentials for credit and debit card processing to your existing bank account',
          idAccount: 'Identification associated with account',
          commissions:
            'Commissions and tax rates will be assigned by the bank.',
          auth: 'I declare that I have authorization to carry out this procedure',
          donwload_app: 'Download the app for free',
          documentEmptyTitle: 'Missing document',
          documentEmptyDescription:
            'You must add the requested legal document.',
          successTitle: 'SUCCESSFUL REQUEST',
          successDescription:
            'The request has been sent successfully. An agent from our partner bank will be contacting you to continue the process.',
        },
        ecommerceCredentials: {
          description:
            'Ecommerce credentials are what allow you to process and receive payment with debit and credit cards for the sale of your products or services through Passer services. The fees you add here will be charged to end users as part of the cost for services. you might be interested to see ',
          getCredentials: '"How to get my credentials"',
          credentialsAdded: 'ADDED CREDENTIALS',
          menu: [
            { icon: 'edit', name: 'Edit' },
            { icon: 'delete', name: 'Delete' },
          ],
          addCredentials: 'Add credentials',
          infoCredential: 'CREDNTIAL INFORMATION',
          userPasswordTitle: 'USER AND PASSWORD',
          userPasswordDescription:
            'Enter the username and password for bank processing connection',
          retentionTitle: 'RETENtIONS',
          retentionDescription:
            'Passer Payments performs the withholding compensation for the bank fee. This will be added to the final rate of online sales, it does not apply to physical ticket office or electronic payment.',
          commissionBankPercentage: 'Bank commission (Percentage)',
          ivaWithholding: 'IVA withholding (Percentage)',
          emptyEditCredential:
            'Press "Edit" to consult and modify ecommerce credentials.',
          emptyEditCredentialSubscription:
            'Press "Edit" to consult and modify connected ecommerce credentials.',
          emptyTitle: 'No ecommerce credentials',
          emptyDescription:
            'You have not created your ecommerce credentials yet. You can create a new one, or modify an old one.',
          deleteFailTitle: 'ACTION NOT ALLOWED',
          deleteFailDescription:
            'These credentials are connected to a subscription. Please disconnect the subscription first in order to delete them.',
          deleteSuccessDescription:
            'Ecommerce credential successfully deleted.',
          updateSuccessDescription:
            'Ecommerce credential successfully updated.',
        },
        sinpeCredentials: {
          description:
            'SINPE credentials are the ones that allow you to process and receive online payments and electronic collection through the National Electronic Payment System directly from the Passer app or payment validators. You might be interested in seeing ',
          addIBANTitle: 'ADD NEW IBAN ACCOUNT',
          addIBANDescription:
            'If you do not yet have a bank account for card payment processing or electronic payment collection, use the <b>"New bank account"</b> section to request them.',
          emptyTitle: 'No SINPE credentials',
          emptyDescription:
            'You have not yet created your SINPE credentials. You can create a new one in the section on the right.',
          menu: [{ icon: 'delete', name: 'Delete' }],
          incorrectCredentialTitle: 'INCORRECT CREDENTIALS',
          incorrectCredentialDescription:
            'Sorry, the IBAN account entered could not be found in the national banking system, please check or consult with your bank and try again.',
        },
        newCredential: {
          title: 'Connection of new processing credentials',
          description:
            'Select a subscription and then assign the processing credentials that will allow you to process and receive online payments or electronic payment funds. Please, ',
          view: 'see disclaimer here',
          pendingSubscription: 'SUBSCRIPTIONS PENDING ASSIGNMENT',
          emptyTitle: 'No subscriptions',
          emptyDescription:
            'There are no pending subscriptions for processing credentials connection. You can create a new one, or modify an old one',
          credentialConnectionTitle: 'CREDENTIALS CONNECTION',
          credentialConnectionDescription:
            'Select the processing credentials that you have previously created for online sales and collection',
          forCardPay: 'FOR CARD PAYMENTS',
          forCardPayDescription:
            'Processing credentials for receiving card payments',
          forSinpePay: 'FOR PAYMENTS THROUGH SINPE (Optional)',
          forSinpePayDescription:
            'IBAN account for online sales through SINPE and receipt of electronic payment funds directly at the bank of your choice.',
          activeSinpe: 'Activate SINPE for online sales',
          useIban:
            'Use my IBAN account to receive electronic payment funds from SINPE-TP',
          successTitle: 'CONNECTION SUCCESSFUL',
          successDescription: 'The company ',
          successDescription2:
            ' you have been successfully connected/modified with the credentials you entered',
          selectSubscription:
            'You must select the subscription you wish to connect.',
        },
      },
      myGroups: {
        title: 'My Groups',
        description:
          'Find here the information of the different administrative groups to which you belong and have access. Remember, if you unsubscribe from a group you will always keep your Passer account active.',
        findGroup: 'Find groups',
        groupOwner: 'Group Owner',
        yourAccess: 'Your Access',
        yourConnection: 'Your connection',
        idGroup: 'Id of the group',
        alreadyGroup: 'You are already working in said group',
        unsubscribeDescription:
          'You are about to unsubscribe for the administrative group <b>{{group}}</b>.<br/>Do you want to continue and unsubscribe?',
        menu: [
          { icon: 'switch-account', name: 'Work here' },
          { icon: 'person-off', name: 'Unsubscribe' },
        ],
        connected: 'Connected',
        disconnected: 'Disconnected',
      },
    },
    actions: {
      off: 'Turn off',
      on: 'Turn on',
      off_on: 'Off/On',
      open: 'Open',
      action: 'Action',
      actions: 'Actions',
      assign: 'Assign',
      successAction: 'Successful action',
      noAction: 'Action not allowed',
      active_deactive: 'Activate/Inactivate',
      deactivate: 'Deactivate',
      change: 'Change',
      change_active: 'Change and activate',
      active: 'Active',
      update: 'Update',
      update_count: 'Update count',
      modify: 'Modify',
      mod_data: 'Modify data',
      modifySubscription: 'Modify subscription',
      inv_Form: 'Invalid format',
      inv_FormC:
        'Invalid format. Allowed formats are: PDF, Doc, Docx, JPG, JPEG, PNG',
      search: 'Search',
      continue: 'Continue',
      see: 'See',
      send: 'Send',
      select_file: 'Select a file',
      select_all: 'Select all',
      select_allnt: 'Deselect all',
      download: 'DownLoad',
      add: 'Add',
      apply: 'Apply',
      accept: 'Accept',
      generate: 'Generate',
      not_apply: 'Do not apply',
      exe: 'Execute',
      enable: 'Enable',
      disable: 'Disable',
      save: 'Save',
      save_ch: 'Save changes',
      ready: 'Ready',
      mult: 'Multi-tariff',
      tarUn: 'Unic fare',
      colect: 'collective',
      direct: 'Direct',
      next: 'Next',
      previous: 'Previous',
      new: 'New',
      close: 'Close',
      cancel: 'Cancel',
      load: 'Load',
      understood: 'Get it',
      process: 'Process',
      filter: 'Filter',
      what_is_this: 'What is this?',
      see_benefits: 'See benefits',
      solicit: 'Solicit',
      add_route: 'Add route',
      save_clean: 'Save and empty form',
      edit: 'Edit',
      edit_stretch: 'Edit stretch',
      new_row: 'New row',
      delete_rows: 'Delete rows',
      delete_stretch: 'Delete stretch',
      verify: 'Verify',
      select: 'Select',
      view: 'view',
      clean: 'Clean',
      delete: 'Delete',
      caution: 'Caution',
      assigning: 'By assigning',
      way_sel: 'Select via',
      seat_sel: 'Select seats',
      print: 'Proceed with printing',
      donwload: 'Proceed with download',
      road_del: 'Delete road',
      add_fr: 'Add fractionation',
      all: 'All',
      allF: 'All',
      val: 'Validar',
      work_here: 'Work here',
      send_Ag: 'Did not you receive the code?',
      refresh: 'Refresh',
      new_copny: 'New Company',
      verify_asoc: 'Verify and associate',
      send_sol: 'Send request',
      send_presol: 'Send prerequest',
      send_report: 'Send report',
      sendCode: 'Send code',
      return: 'Go Back',
      save_config: 'Save configuration',
      sync: 'Synchronize',
      move: 'Move',
      goConnect: 'See documentation',
      executeProtocol: 'Execute protocol',
      seeTransactions: 'View Transactions',
      applyFilter: 'Apply filter',
      calculate: 'Calculate',
      cancelSubscription: 'Cancel Subscription',
      recover: 'Recover',
      dropOut: 'Drop Out',
      regenerate: 'Regenerate',
      connect: 'Connect',
      disconnect: 'Disconnect',
      test: 'Test',
    },
    alerts: {
      sendErr: 'Failed to send code',
      incCode: 'Incorrect code.',
      unknownErr: 'Unknown error.',
      photo: 'The dimensions of the image must be greater than ',
      photo2: ' pixels.',
      credentialsTitle: 'VERIFY IDENTITY',
      credentialsDescription:
        'For security, please re-enter your Passer account password to keep your session active',
      anotherSessionTitle: 'Multiple sessions',
      anotherSessionDescription:
        'We detected that you have several sessions open, we will close this session and you will continue with the last one you logged in with.',
      reSendCode: {
        title: 'Code Forwarding',
        message: 'We have resent the code',
      },
      sel_photo:
        'You must upload an image before you can change it. If you want to exit the modal without making changes, press the "Close" button',
      ERRORS: {
        MINLENGTH: 'The minimum length for this field is',
        MAXLENGTH: 'The maximum length for this field is',
        MIN: 'The minimum amount for this field is',
        MAX: 'The maximum amount for this field is',
        ONLYNUMBERS: 'Only numbers allowed',
        REQUIRED: 'This field is required.',
        CHARS: 'characters',
        INVALID_FORMAT: 'INVALID FORMAT',
        PASSWORD_VALID:
          'The password must have 1 uppercase, 1 lowercase and a number',
        PASSWORD_MINLENGTH: 'The password must have a minimum of 8 characters',
        ERROR_CONFIRMPASSWORD: 'Passwords do not match.',
      },
      sizeImage: 'Maximum upload size is 10 Mb',
      cacheErrors: {
        country: 'Error loading countries.',
        tipeId: 'Error loading identification types.',
        businessActivity: 'Error loading business activity types.',
        positions: 'Error loading position types.',
        funds: 'Error loading fund types.',
        currency: 'Error loading currency types.',
      },
    },
    datetime: {
      dateTime: 'Date/Hour',
      date: 'Date',
      hour: 'Hour',
      transactionDate: 'Transaction date',
      transactionHour: 'Transaction hour',
      'month/year': 'Month / Year',
      yearly: 'Yearly',
      monthly: 'Monthly',
      registrationDate: 'Registration date',
      birthday: 'Birthday',
      creationDate: 'Creation date',
      rangeDates: 'Date range',
      initHour: 'Start hour',
      finHour: 'Final hour',
      constitutionDate: 'Constitution date',
    },
    enterprisesConnect: {
      title: 'ENTERPRISES CONNECT',
      credentials: {
        title: 'CREDENTIALS',
        desc: 'Enterprises Connect allows you to take advantage of all Passer technology and infrastructure in your own projects and developments.',
        subsAccessTitle: 'SUBSCRIPTIONS WITH ACCESS TO CONNECT',
        subsAccessDescription:
          'Use the access token of each subscription to authenticate your development. If the company you need to connect is not shown in the list, it is because the appropriate subscription type was not created for it. Change the type of subscription to be able to generate the credentials for development.',
        tokenCopy: 'Copied token',
        emptyTitle: 'No subscriptions created',
        emptyDescription:
          'There are no subscriptions created. You can create a new one in the "Subscriptions" menu option and in the "NEW SUBSCRIPTION" tab.',
        successRegenerate: 'Your token has been successfully regenerated.',
        noToken:
          'It appears that your subscription does not have a generated token. You can use the "Regenerate Token" option to be able to assign a token to your subscription.',
        menu: [
          { icon: 'eye', name: 'View/Hide token' },
          { icon: 'token', name: 'Regenerate token' },
          { icon: 'content-copy', name: 'Copy token' },
          { icon: 'code', name: 'Configure Connect' },
        ],
      },
      configureConnect: {
        title: 'Configure Enterprises Connect',
        description:
          'Activate Connect to integrate your own infrastructure and systems and thus begin sending information and processing data through the Enterprises Connect APIs. If you make the connection you will have to write the logic of some basic functions of the system.',
        acceptRules:
          'I agree that I have read and understood the rules and guidelines for creating functions. ',
        acceptRules2: 'Check rules',
        workWith: 'WORK WITH CONNECT',
        accessChangeTitle: 'Disable Connect',
        accessChangeDescription:
          'You are about to disable all Connect functionality. Do you want to continue?',
        changeAccess:
          'The action has been carried out successfully, if you have questions you can contact Enterprises support',
        tabs: [
          {
            name: 'FUNCTIONS CREATED',
            action: 'functionCreated',
          },
          {
            name: 'NEW FUNCTION',
            action: 'newFunction',
          },
        ],
        createdFunctions: {
          emptyListTitle: 'No functions',
          emptyListDescription:
            'You have not programmed the function logic yet.',
        },
        newFunction: {
          selectFunction: 'Select the function you are going to write or edit',
          changesFunction:
            'The function has been created/modified successfully.',
          requiredData:
            'You must enter the functions code to be able to perform this action.',
          testFunction: 'The test of the selected function was successful.',
        },
      },
      modals: {
        connectTitle: 'ENTERPRISES CONNECT',
        connectDesc:
          'Enterprises Connect APIs allow you to take advantage and connect your own developments. It is important that you configure the basic functions of the system. ',
        connectDesc2: 'Learn more here.',
        connectBtn: 'Configure Connect',
        regenerateTokenTitle: 'REGENERATE TOKEN',
        regenerateTokenDescription:
          'Be careful, you are about to regenerate the identification token. If you do it, you will disconnect any services you have integrated. Make sure to add this new token in all your integrations.',
      },
    },
    finance: {
      title: 'FINANCING PROGRAM',
      subTitle: 'Passer + Coopenae',
      desc: 'They have joined forces to create a financing program according to the new needs of public transport and its modernization. Credit for electronic payment is a product designed to adapt to the particularities and requirements of the industry, allowing your company to access new technologies without having to make heavy investments or complicated procedures.',
      tabs: [
        {
          name: 'BENEFICIOS',
          action: 0,
        },
        {
          name: 'CONOCER LOS VALIDADORES',
          action: 1,
        },
      ],
      tabsRight: [
        {
          name: 'REQUISITOS',
          action: 0,
        },
        {
          name: 'CALCULAR CUOTA',
          action: 1,
        },
      ],
      benefit: {
        title: 'More benefits',
        desc: 'Enjoy the best conditions, not only in payments, but also in support and advice so that you get the best benefit and return on your investment.',
        checks: [
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy',
        ],
        partners: 'PARTNER BENEFITS',
        box: [
          {
            title: 'Apply your subscription fee to the payment of your credit',
            desc: 'We will redirect the monthly/annual payment of your Passer subscription to fully or partially cover the payment of your credit.',
          },
          {
            title: 'Special rate validators',
            desc: 'Receive up to 20% discount on the base price of the validators, which at the same time will reduce the payment of your credit.',
          },
        ],
      },
      validator: {
        title: 'EMV/PCI Validators',
        desc: 'Together with our business partner, ',
        desc2:
          ', a renowned European company specializing in electronic payment solutions, we have integrated the most modern and robust validator solution into our software systems and backoffice components.',
        ppt: 'PPT Excellence',
        equip: 'Equipped with the best industry standards',
        equipList: [
          'EMVco L1 and EMV L2 certifications',
          'EMV L3 ready',
          'PCI - PTS 5.1',
          'Remote Key Injection, PCI DSS',
          'SAM Card port for interaction under PCI environment - PTS POI',
          'All automotive and railway industry certifications: FCC - ISED and R&TTE',
        ],
        runwayTitle: 'Payment gateway + Passer/PPT + Bank + Your company',
        runwayDescription:
          'Our validators and backoffice systems are ready to operate and interact directly with your acquiring bank or mediating payment gateways, such is the case of LittlePay in Costa Rica. Contact your account executive to learn more about our processing ecosystem.',
        support: 'With the support and quality of',
      },
      requirement: {
        title:
          'Select the documents that you will be providing in this pre-request',
        list: [
          {
            action: false,
            name: 'Photocopy of identity card of the applicant',
          },
          { action: false, name: 'CCSS or INS certificate (one of the two)' },
          { action: false, name: 'Copy of the last two income tax returns' },
          { action: false, name: 'Legal Status with Share Participation' },
          { action: false, name: 'Literal Legal Status' },
          {
            action: false,
            name: 'Financial Statements for the last three years',
          },
          {
            action: false,
            name: 'Financial court with no more than three months old',
          },
          {
            action: false,
            name: 'Projected cash flow for one year, expressed on a monthly basis (must include debt payment, future expenses and future income)',
          },
        ],
      },
      calculate: {
        vehicle: 'Calculate with system vehicles',
        another: 'Another amount',
        totalBase: 'TOTAL CREDIT BASE',
        coopenaeCom: 'Coopenae Commission',
        amount: 'Disbursement amount',
        monthlyEst: 'YOUR ESTIMATED MONTHLY QUOTE WOULD BE',
        footer:
          'The amounts indicated are an estimate, and do not include costs of policies and insurance. A Coopenae executive will contact you to give you more details. Submitting this pre-application is not a guarantee of approval, nor does it imply a commitment for you.',
      },
    },
    footer: {
      nav: {
        terms: 'Terms of services',
        guide: 'User guide',
        support: 'Business support',
        app: 'Passer App',
        passer: 'Passer.com',
      },
      login: {
        1: 'Support',
        2: 'Terms of use',
        3: 'Developers',
      },
    },
    global: {
      errorTimeoutTitle: 'No response',
      errorTimeoutDescription:
        'It seems that something is wrong and there is no response from the server. Our apologies. Please wait and try in a few minutes.',
      errorMaintenanceTitle: 'Under maintenance',
      errorMaintenanceDescription:
        'Our apologies, the service is under maintenance. It will be restored as soon as possible. Please try in a few minutes.',
      errDTSTitle: 'Connection problems',
      errDTSDescription:
        'Sorry, it seems that there was an Internet micro cut. Close this window and try again, if the error persists contact us.',
      erroServerTitle: 'Failed to get data',
      erroServerDescription:
        'Unable to get the information for your account, please try again',
      passer_error_title: 'An error has occurred',
      passerErrorTitle2: 'Closed system',
      passer_error_message:
        'Sorry, something has happened and it is not possible to process the request, please try again',
      passerErrorMessage2:
        'For security reasons the system was closed and you will have to logIn again.',
      passer_error_cache:
        'Sorry, something has happened with the logout request, please try again.',
      passer_idp_error_invalid_grant:
        'Login failed; Invalid email or password.',
      passer_idp_error_invalid_management_group:
        'Sorry, the logged in user does not have access to this resource, please try another or contact your Enterprises account manager.',
      learnMore: 'You can learn more about how it works here',
      loadCrop: 'Load and crop your company logo',
      loadCropWall: 'Load and crop yourcompany cover',
      loadImage: 'Load image',
      logWith: 'logged in as:',
      inProcessD: 'This system function is still in process.',
      noResults: 'There are no records according to the search parameters.',
      managementGroup: 'Management group',
      revokeAccess: 'Revoke access',
      grantAccess: 'Grant access',
      changeManagementGroup: 'Administrative group changed successfully.',
      deleteManagementGroup: 'Administrative group deleted successfully.',
      successActionMessage:
        'The changes or settings have been generated and/or saved successfully.',
    },
    home: {
      title: 'Passer EN',
      chId: 'Change Language',
      chIdC: 'Change language',
      spanish: 'Spanish',
      english: 'English',
    },
    labels: {
      auth: 'Authorization',
      access: 'Access',
      typeAccess: 'Access type',
      success: 'Success',
      fail: 'Failed',
      currentPass: 'Current password',
      newPass: 'New password',
      confirmPass: 'Confirm password',
      twoSteps: 'Verification of two-steps',
      pinAccount: 'Account PIN',
      warning: 'Warning',
      capacity: 'Capacity',
      warn: 'Warning',
      apt: 'Opening',
      connection: 'Connection',
      connections: 'Conections',
      disconn: 'Disconnected',
      features: 'Features',
      cirr: 'Closing',
      route: 'Route',
      center: 'Center',
      desc: 'Description',
      road: 'Way',
      road_Frac: 'Road or fractionation',
      depPoint: 'Departure point',
      arrPoint: 'Arrival point',
      price: 'Price',
      unit: 'Unit',
      doc: 'Documentation',
      document: 'Document',
      fracc: 'Stretch',
      copny: 'Company',
      ced: 'Identification card',
      ced_fis: 'Physical ID',
      ced_jud: 'Legal ID',
      ced_red: 'Residence ID',
      gob: 'Government Entity',
      ente_aut: 'Autonomous Entity',
      passport: 'Passport',
      origin: 'Origin',
      destination: 'Destination',
      journey: 'Journey',
      schedule: 'Schedule',
      female: 'Female',
      male: 'Male',
      other: 'Other',
      opt_select: 'Selected options',
      days_filter: 'Filter days',
      copny_filter: 'Filter company',
      perm_filter: 'Filter permission',
      all_selec: 'All selected',
      name: 'Name',
      lastname: 'Surnames',
      ms: 'Message',
      photo: 'Photo',
      reason: 'Reason',
      place: 'Place',
      num_unit: 'Unit number',
      code: 'Code',
      customer_name: 'Customer full name',
      driver: 'Driver',
      route_name: 'Route name',
      route_code: 'Route code',
      route_path: 'Find route path',
      yes: 'Yes',
      no: 'No',
      phone: 'Phone',
      bank: 'Bank',
      account: 'Account',
      ibanAccount: 'IBAN account',
      accountOwner: 'Account owner',
      owner: 'Owner',
      iban: 'IBAN',
      type: 'Type',
      appPassword: 'AppPassword',
      appName: 'AppName',
      movil_phone: 'mobile phone',
      phoneAccount: 'Phone account',
      emailAccount: 'Email account',
      emailUser: 'User email',
      print: 'Print',
      year: 'Year',
      number: 'Number',
      act: 'Act',
      inac: 'Inac',
      email: 'Email',
      pass: 'Password',
      user: 'User',
      type_id: 'ID type',
      typeId: 'Identification type',
      id: 'Identification',
      tel_assig: 'Assigned telephone',
      tick_online: 'With online sale',
      urb_offline: 'No online sale',
      tik: 'Interurban',
      urb: 'Urban',
      train: 'Train',
      passengers: 'passengers',
      votes: 'votes',
      via_tracs: 'Ways / Subdivisions',
      window_dig: 'Digital window',
      boarding: 'Boarding',
      stair: 'Stairs',
      numRow: 'Row number',
      row: 'Row',
      moves: 'Transactions',
      ini_role: 'Start role',
      fin_role: 'End role',
      tick_off: 'Electronic',
      tick_on: 'Online',
      reject: 'Rejections',
      pending: 'pending',
      total_rec: 'Total collection',
      retention: 'retention',
      settl: 'Settlement',
      date_role: 'Role date',
      date_liq: 'Settlement date',
      total_pend: 'Total pending',
      total_acre: 'Total accreditation',
      vehicle: 'Vehicle',
      amount: 'Amount',
      nationality: 'Nationality',
      country: 'Country',
      canton_dep: 'Canton/Department',
      dist_city: 'District/City',
      recurrent: 'Recurrent',
      init: 'Start',
      end: 'Finish',
      first: 'First',
      last: 'Last',
      country_operation: 'Country of operation',
      country_birth: 'Country of birth',
      country_res: 'Country of residence',
      entity_type: 'Entity type',
      legal_name: 'Legal name',
      business_name: 'Business Name',
      level: 'Level',
      bank_cred: 'Credentials accreditations',
      type_device: 'Device type',
      phone_optional: 'Phone number (optional)',
      profOcup: 'Profession/Occupation',
      currency_ing: 'Income currency',
      ing_mnth: 'Average monthly income',
      address_ex: 'Exact address',
      exp_id: 'Identification expiration',
      ing_lab: 'Date of entry to work',
      ing_pos: 'Date of entry to the position',
      label: 'Label',
      rates: 'Rates',
      rate: 'Rate',
      info: 'Information',
      vehicles: 'Vehicles',
      spaces: 'Spaces',
      more: 'more',
      position: 'Position',
      positionCopny: 'Position in company',
      positionPublic: 'Public position',
      subscriptionType: 'Subscription Type',
      status: 'Status',
      plan: 'Plan',
      monthlyRateBase: 'Monthly base rate',
      renovation: 'Renovation',
      secretToken: 'Secret token',
      location: 'Location',
      device: 'Device',
      midEnterprises: 'MID Enterprises',
      moreFilter: 'More filters',
      userClient: 'User/Client',
      paymentForm: 'Payment method',
      brand: 'Brand',
      card: 'Card',
      transactionType: 'Transaction type',
      currency: 'Currency',
      main: 'Owner',
      debts: 'Debts',
      creationDate: 'Creation Date',
      lastUse: 'Last Use',
      sex: 'Gender',
      purchaseQu: 'Quantity purchases',
      quantity: 'Quantity',
      numeration: 'Numeration',
      shopping: 'Shopping',
      holder: 'Card holder',
      msgAdq: 'Acquirer Message',
      issuingBank: 'issuing bank',
      originCountry: 'Country of origin card',
      mid: 'MID',
      term: 'Term',
      colons: 'Colones',
      dollars: 'Dollars',
      months: 'months',
      inProcess: 'In process',
      subscriptionGroup: 'Subscription/Group',
      subscription: 'Subscription',
      connectStatus: 'Connect status',
      active: 'Active',
      inactive: 'Inactive',
      functions: 'Functions',
      function: 'Function',
      modification: 'Modification',
      platform: 'Platform',
      terminal: 'Terminal',
      reference: 'Reference',
      commissionBank: 'Bank Commission',
      tax: 'Taxation',
      bankAcquisition: 'Bank Acquisition',
      paymentGateway: 'Payment gateway',
      percentage: 'Percentage',
      fixed: 'Fixed',
      ownerAccount: 'Account Owner',
      accepted: 'Accepted',
      refused: 'Refused',
      reversed: 'Reversed',
      security: 'Security',
      accessLevel: 'Access level',
      noAccess: 'No access',
      version: 'Version',
    },
    LANGUAGE: {
      TITLE: 'CHANGE LANGUAGE',
      CHANGE_LAN: 'Language change',
      CHANGE_LAN_Q: 'Do you want to change the Language?',
    },
    login: {
      sign: 'Sign in',
      forg_pass: 'Did you forget the password?',
      no_account:
        'If you still do not have a Passer Enterprises account, register for free so that your company evolves to electronic and digital.',
      register_copny: 'Register my company',
      logOut: 'Sign out',
      backLogin: 'Back to login',
      twoFactor: {
        title: 'TWO-FACTOR AUTHENTICATION',
        description:
          'Two-factor authentication requires you to enter a verification code that was sent to your Passer account email or phone number',
        question: 'Where do you want to receive the verification code?',
        radioChoose: [
          {
            desc: 'Mobile phone',
            selected: true,
          },
          {
            desc: 'Email',
            selected: false,
          },
        ],
        confirmation: 'You will receive the code the phone ends in',
      },
      insertCode: {
        title: 'ENTER CODE',
        description: 'We have sent the code to the ',
        descriptionRecover:
          'Please enter the code that has arrived in your Passer account email',
        question: 'Not receiving the code?',
      },
      deletedAccount: {
        title: 'ACCOUNT CANCELED',
        descOne:
          'The account for the company {company} has been successfully suspended.',
        descTwo:
          'If you need to re-enter you can do so with your Passer account credentials. In case you need to reinstall validators or other hardware devices please contact',
        email: 'enterprises@passer.live',
        descThree: 'or phone',
        phoneNumber: '+506 4001-4623',
      },
      selectCompany: {
        title: 'SELECT A COMPANY',
        description: 'Select the company you want to start this session with',
        suscription_status: {
          1: 'Cancelled',
          2: 'Active',
          3: 'Pending',
          4: 'Suspended',
        },
      },
      recoverPassword: {
        title: 'RECOVER PASSWORD',
        desc: 'Enter your Passer account email',
        return: 'Return to login',
      },
      newPassword: {
        title: 'NEW PASSWORD',
        desc: 'Create a new password for your Passer account',
      },
      selectGroup: {
        title: 'SELECT A GROUP',
        description:
          'Select the administrative group you want to start this session with.',
        owner: 'Owner:',
        role: 'Your role:',
      },
    },
    navbar: {
      accountProduct: 'Passer account',
      suscription: 'Subscriptions',
      accessSecurity: 'Access and security',
      enterprisesConnect: 'Enterprises Connect',
      posConnect: 'POS Connect',
      paymentMethod: 'Payment methods',
      accountPayments: 'Transactions',
      guides: 'USER GUIDES',
      openSupportTicket: 'OPEN A SUPPORT TICKET',
      visitBlog: 'VISIT THE BLOG',
      programFinance: 'FINANCING PROGRAM',
    },
    notFound: {
      title: 'SOMETHING STRANGE HAPPENS',
      desc: 'The page you are looking for disappeared',
      come_back: 'Back to begining',
    },
    subscription: {
      title: 'SUBSCRIPTIONS',
      list: {
        title: 'LIST',
        goDashboard: 'Go to Dashboard',
        emptyTitle: 'No subscriptions created',
        emptyDescription:
          'There are no subscriptions created. You can create a new one in the "NEW SUBSCRIPTION" tab.',
        menu: [
          { icon: 'link', name: 'Connect SINPE - TP' },
          { icon: 'link-off', name: 'Disconnect SINPE - TP' },
          { icon: 'description', name: 'Subscription details' },
        ],
      },
      newSubscription: {
        title: 'NEW SUBSCRIPTION',
        codeTitle: 'DISCOUNT CODE',
        codeDescription: 'If you have a promotional code, enter it here',
        codeApply: 'Promotional code applied successfully.',
        registerData: {
          loadAvatar: 'Load your company cover',
          copnyTitle: 'COMPANY INFORMATION',
          copnyDesc:
            'Add the company information that will be related to this subscription.',
          legalRepTitle: 'LEGAL REPRESENTATIVE',
          legalRepDescription:
            'Add the information of the physical person who represents the company.',
          termsTitle: 'TERMS AND CONDITIONS OF YOUR SERVICE',
          termsDescription:
            'This information will be reflected on the Passer website so that users can consult it and learn about your use policies.',
          change_reemb: 'Exchange and refund policies',
          sched_serv: 'Policies on service hours',
          user_at: 'Policies on user care',
          other_serv: 'Other considerations about services',
          twoRepresentatives: 'You can only add two representatives',
          emptyDataTitle: 'Incomplete data',
          emptyDataDescription:
            'Make sure you have entered at least one representative.',
          noTerms:
            'I do not have my own policies, use the general policies of Passer Enterprises.',
        },
        plans: {
          title: 'Subscription type and plans',
          subtitle: 'TYPE OF SUBSCRIPTION',
          description:
            'Select the option that you consider best fits your transportation operation. If you have questions or queries, contact your account executive who will help you in the process.',
          types: [
            {
              title: 'OPENING ONLINE',
              boxButtonTitle: 'RECOMMENDED',
              boxTitleBlue: '100% online and offline sales',
              boxDescriptionBlue:
                'Improve the experience of your services by allowing your users to always enjoy being able to reserve their seats online in a 100% guaranteed way.',
              boxInfoT: 'RECOMMENDED',
              boxInfoD:
                'Now up to <span class="f18 fw-bold">25%</span> in your plan compared to the Opening type',
              enable: true,
              info: [
                'Maximum reduction of theft and costs associated with cash handling',
                '1% marginal bonus for each transaction',
                'Digital control of boardings, collection and settlements',
                'Your users will be able to reserve the services of all your routes from the app',
                'Digital window 24/7/365 from the mobile app',
                'Your users will be able to reserve the services of all your routes from the app',
                'Real-time control of collection by route and vehicle',
                'Access to the Bus Driver app',
                'Unlimited access to all modules and system improvements',
              ],
            },
            {
              title: 'OPENING',
              boxTitleBlue: 'Flexible digital sales',
              boxDescriptionBlue:
                'Decide which of your services users can purchase digitally from the app.',
              enable: true,
              info: [
                'Good reduction in theft and costs associated with cash handling',
                'Digital control of boardings, collection and settlements',
                'Digital window 24/7/365 from the mobile app for the services you choose',
                'Real-time control of collection by route and vehicle',
                'Access to the Bus Driver app',
                'Unlimited access to all modules and system improvements',
              ],
            },
            {
              title: 'BASE',
              boxTitleBlue: 'To start',
              boxDescriptionBlue:
                'Recommended for you to start modernizing your company, having better controls. A gradual migration from analog to digital begins.',
              enable: true,
              info: [
                'Access to all basic modules, including physical ticket office',
                'Improve the efficiency of your processes',
              ],
            },
          ],
          button: 'SUBSCRIBE THIS PLAN',
          desTitle:
            'Select the plan that best suits the operation of the company you are going to register. The rates vary depending on the type of subscription',
          selectOption: 'SELECT AN OPTION',
          anual: [
            {
              title: 'START',
              topics: [
                {
                  topLabel: 'Monthly',
                  value: '$300.00',
                  description: 'USD / month',
                  extraMessage: 'Monthly charge to your card',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.5%',
                  description: 'Transaction',
                  show: false,
                },
                {
                  topLabel: 'Electronic payment',
                  value: 'Soon',
                  extraMessage:
                    'The electronic payment fee has not yet gone live in your country, so you will not be charged for this',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description: 'Add up to 20 operational vehicles',
                  description1: 'Add up to ',
                  description2: ' operating vehicles',
                },
              ],
            },
            {
              title: 'MEDIUM',
              topics: [
                {
                  value: '$420.00',
                  description: 'USD / month',
                  extraMessage: 'Monthly charge to your card',
                  topLabel: 'Monthly',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.5%',
                  description: 'Transaction',
                  extraMessage:
                    'This percentage will be applied when you make sales from the ticket office module',
                  show: false,
                },
                {
                  topLabel: 'Electronic payment',
                  value: 'Soon',
                  extraMessage:
                    'The electronic payment fee has not yet gone live in your country, so you will not be charged for this',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description: '21 to 60 operational vehicles',
                  description1: 'Add up to ',
                  description2: ' operating vehicles',
                },
              ],
            },
            {
              title: 'PLUS',
              topics: [
                {
                  topLabel: 'Monthly',
                  value: '$915.00',
                  description: 'USD / month',
                  extraMessage: 'Monthly charge to your card',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.5%',
                  description: 'Transaction',
                  extraMessage:
                    'This percentage will be applied when you make sales from the ticket office module',
                  show: false,
                },
                {
                  topLabel: 'Electronic payment',
                  value: 'Soon',
                  extraMessage:
                    'The electronic payment fee has not yet gone live in your country, so you will not be charged for this',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description: 'More than 61 operational vehicles',
                  description1: 'More than ',
                  description2: ' operating vehicles',
                },
              ],
            },
            {
              title: 'PARTNER',
              isSpecial: true,
              topics: [
                {
                  topLabel: 'Sign up today',
                  value: 'Only ${{price}} a year',
                  description:
                    'An exclusive way to transform and maximize the performance of your company.',
                  show: true,
                },
                {
                  list: true,
                  isSimple: true,
                  title: 'Requirements',
                  description:
                    'A 100% transparent benefit, all you have to do to be a partner is',
                  show: true,
                  listOfPoints: [
                    'Add, and maintain active, at least 10 service schedules per day in one direction of travel.',
                    'Have an average fare of $10 or more (or its equivalent in local currency)',
                    'Keep at least 80% of the routes created active and with online sales',
                  ],
                },
                {},
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description:
                    'Add and operate all the vehicles you need, without limits or costs',
                },
              ],
            },
          ],
          monthly: [
            {
              title: 'START',
              topics: [
                {
                  topLabel: 'Monthly',
                  value: '$345.00',
                  description: 'USD / month',
                  extraMessage: 'Monthly charge to your card',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.7%',
                  description: 'Transaction',
                  show: false,
                },
                {
                  topLabel: 'Electronic payment',
                  value: 'Soon',
                  extraMessage:
                    'The electronic payment fee has not yet gone live in your country, so you will not be charged for this',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description: 'Add up to 20 operational vehicles',
                  description1: 'Add up to ',
                  description2: ' operating vehicles',
                },
              ],
            },
            {
              title: 'MEDIUM',
              topics: [
                {
                  topLabel: 'Monthly',
                  value: '$483.00',
                  description: 'USD / month',
                  extraMessage: 'Monthly charge to your card',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.7%',
                  description: 'Transaction',
                  extraMessage:
                    'This percentage will be applied when you make sales from the ticket office module',
                  show: false,
                },
                {
                  topLabel: 'Electronic payment',
                  value: 'Soon',
                  extraMessage:
                    'The electronic payment fee has not yet gone live in your country, so you will not be charged for this',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description: '21 to 60 operational vehicles',
                  description1: 'Add up to ',
                  description2: ' operating vehicles',
                },
              ],
            },
            {
              title: 'PLUS',
              topics: [
                {
                  topLabel: 'Monthly',
                  value: '$1.053.00',
                  description: 'USD / month',
                  extraMessage: 'Monthly charge to your card',
                  show: true,
                },
                {
                  topLabel: 'Ecommerce',
                  value: '1.7%',
                  description: 'Transaction',
                  extraMessage:
                    'This percentage will be applied when you make sales from the ticket office module',
                  show: false,
                },
                {
                  topLabel: 'Electronic payment',
                  value: 'Soon',
                  extraMessage:
                    'The electronic payment fee has not yet gone live in your country, so you will not be charged for this',
                  show: false,
                },
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description: 'More than 61 operational vehicles',
                  description1: 'More than ',
                  description2: ' operating vehicles',
                },
              ],
            },
            {
              title: 'PARTNER',
              isSpecial: true,
              topics: [
                {
                  topLabel: 'Sign up today',
                  value: 'Only ${{price}} a month',
                  description:
                    'An exclusive way to transform and maximize the performance of your company.',
                  show: true,
                },
                {
                  list: true,
                  isSimple: true,
                  title: 'Requirements',
                  description:
                    'A 100% transparent benefit, all you have to do to be a partner is',
                  show: true,
                  listOfPoints: [
                    'Add, and maintain active, at least 10 service schedules per day in one direction of travel.',
                    'Have an average fare of $10 or more (or its equivalent in local currency)',
                    'Keep at least 80% of the routes created active and with online sales',
                  ],
                },
                {},
                {
                  isSimple: true,
                  value: 'Vehicles',
                  description:
                    'Add and operate all the vehicles you need, without limits or costs',
                },
              ],
            },
          ],
          subscriptionSelect:
            'You must select a subscription type before subscribing to a plan.',
          choosePlan: 'CHOOSE PLAN',
          back: 'BACK TO TYPES',
          register: 'REGISTER SUBSCRIPTION',
        },
      },
      remindSubscription: {
        title: 'ADD A SUBSCRIPTION',
        desc: 'Register and set up your first business subscription. In this way you can start creating services, selling online and much more.',
        add: 'Add now',
      },
      modals: {
        subscriptionCreatedTitle: 'SUCCESSFULLY GENERATED SUBSCRIPTION',
        subscriptionCreatedDescription1: 'Your subscription type <b>',
        subscriptionCreatedDescription2: '</b> with the plan <b>',
        subscriptionCreatedDescription3:
          '</b> has been successfully registered. ',
        subscriptionCreatedDescription4:
          'You can now start creating services and managing your operation.',
        subscriptionCreatedDescription5:
          'An account executive will contact you within a maximum period of 48 business hours to collaborate with you.',
        subscriptionChangeTitle: 'SUCCESSFUL CHANGE',
        subscriptionChangeDescription:
          'The modality of your subscription has been changed successfully.',
        sinpeTitle: 'SINPE-TP',
        sinpeDescription:
          'We are working so that you can soon connect to the central collection system of the BCCR. For more information you can contact us enterprises@passer.live',
      },
      detail: {
        title: 'Subscription details',
        info: 'REGISTRY INFORMATION',
        canUpdate:
          'You can modify the registration data and other information from the "Company" section of the subscription dashboard.',
        cancelTitle: 'Cancel subscription',
        cancelDescription:
          'You are about to cancel your subscription <b>{{subs}}</b>. Do you want to continue?',
        updateTitle: 'Modify subscription',
        updateDescription:
          'You are about to modify the subscription <b>{{subs}}</b>. Do you want to continue?',
      },
      status: {
        inactive: 'Inactive',
        active: 'Active',
        pending: 'Pending',
        suspended: 'Suspended',
        canceled: 'Canceled',
        list: ['Active', 'Suspended', 'Canceled'],
      },
    },
    suspendedAccess: {
      title: 'SUSPENDED ACCESS',
      description:
        'A group owner or administrator has revoked your access to this subscription or account. Please try to login with another user or contact your company administration.',
      return: 'Back to login',
    },
    payment_method: {
      title: 'Payment methods',
      aso_cardsTitle: 'Associated cards',
      aso_cardsDescription:
        'You must have at least one active payment method to maintain your subscription.',
      make_princ: 'Make principal',
      principal: 'Principal',
      new_meth: 'New payment method',
      holder: 'Name of card holder',
      card_number: 'Card numbering',
      exp_seg: 'Expiration and security',
      history_pay: 'Payment history',
      period: 'Period',
      per_coll: 'Period',
      paym_meth: 'Payment method',
      pay: 'Pay',
      success: 'Success',
      fail: 'Failed',
      pending: 'Pending',
      alert_susp:
        'Access to this company has been suspended due to non-payment. Please update your card details and try again.',
      err_load: 'Error loading cards',
      err_load_history: 'Error loading payment history',
      successCreate: 'Payment method created successfully.',
      successUpdate: 'Principal payment method updated successfully.',
      successDelete: 'Payment method deleted successfully.',
      warningPayTitle: 'Make payment',
      warningPayDescription:
        'You are about to pay for the subscription <b>{{subs}}</b>, for the period <b>{{period}}</b>. Do you want to continue?',
    },
  },
};
