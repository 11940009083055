import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NormalModalComponent } from '@components/normal-modal/normal-modal';
import { ROUTES_ADMIN } from '@constants/routes';
import { IdPParamKeys } from '@enums/idpparamkeys.enum';
import { StorageKeys } from '@enums/storage-keys.enum';
import { environment } from '@environments/environment';
import {
  ResponseGeneral,
  ResponseGeneralMini,
} from '@models/responses/response-general';
import {
  IdPAccessTokenRequest,
  IdPAccessTokenResponse,
  IdPRefreshTokenRequest,
  ResponseLogin,
} from '@models/responses/response-login';
import * as PasserIdP from '@models/state/passerIdP.state';
import {
  ManagementGroup,
  RoleAssignment,
  User,
} from '@models/state/user.model';
import { LoaderManagmentService } from '@providers/loader-managment/loader-managment.service';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { ModalService } from '@providers/modal/modal.service';
import { SessiondataService } from '@providers/session-data/session-data.service';
import { StorageService } from '@providers/storage/storage.service';
import { TranslationService } from '@providers/translation-new/translation.service';
import { UserManagerService } from '@providers/user-manager/user-manager.service';
import { SharedService } from '@providers/shared/shared.service';
import { lastValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  url: string;
  passerIdP: {
    options: PasserIdP.Options;
  };

  private _unauthorized = false;
  private readonly TOKEN = '/token';
  private readonly USER_INFO = '/me';
  private readonly USER_MANAGEMENT_GROUPS_INFO =
    '/api/providers/Passer.Management/accounts/{{userSub}}/managementGroups';
  private readonly USER_ROLEASSIGNMENT =
    "/api/providers/Passer.Management/managementGroups/{{managementGroupId}}/providers/Passer.Authorization/roleAssignments?$filter=assignedTo('{{userSub}}')";
  private readonly RECOVER_PASSWORD =
    '/api/providers/Passer.Authorization/recovery/password';
  private readonly CHANGE_PASSWORD =
    '/api/providers/Passer.Authorization/accounts/{{userSub}}/password/change';
  private readonly LOGOUT =
    '/api/providers/Passer.Authorization/accounts/{{userSub}}/logout';

  private readonly REQUEST = '/api/auth/otp';
  private readonly VERIFY = '/api/auth/verify-otp';
  private readonly VERIFY_RESET = '/api/auth/verify-reset-otp';

  constructor(
    private http: HttpClient,
    private loading: LoaderManagmentService,
    private methodGeneral: MethodsGeneralService,
    private modalService: ModalService,
    private router: Router,
    private session: SessiondataService,
    private sharedService: SharedService,
    private storage: StorageService,
    private translation: TranslationService,
    private userManager: UserManagerService
  ) {
    this.url = environment.SERVER_URL;
    this.passerIdP = { options: environment.passerIdP.options };
  }

  async loginStart(
    email: string,
    password: string
  ): Promise<IdPAccessTokenResponse> {
    const requestHeaders = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    const accessTokenRequest: IdPAccessTokenRequest = {
      grant_type: 'password',
      client_id: this.passerIdP.options.clientId,
      client_secret: this.passerIdP.options.clientSecret,
      username: email,
      password: password,
      scope: this.passerIdP.options.scope,
    };

    const login$ = this.http.post<IdPAccessTokenResponse>(
      this.passerIdP.options.serverUrl + this.TOKEN,
      this.methodGeneral.toQueryString(accessTokenRequest),
      { headers: requestHeaders }
    );

    return await lastValueFrom(login$);
  }

  async userInfo(): Promise<User> {
    const user$ = this.http.get<User>(
      this.passerIdP.options.serverUrl + this.USER_INFO
    );

    return await lastValueFrom(user$);
  }

  async userManagementGroupsInfo(userSub: string): Promise<ManagementGroup[]> {
    const management$ = this.http.get<ManagementGroup[]>(
      this.passerIdP.options.serverUrl +
        this.USER_MANAGEMENT_GROUPS_INFO.replace(IdPParamKeys.UserSub, userSub)
    );

    return await lastValueFrom(management$);
  }

  async userRoleAssignment(
    managementGroupId: string,
    userSub: string
  ): Promise<RoleAssignment[]> {
    const role$ = this.http.get<RoleAssignment[]>(
      this.passerIdP.options.serverUrl +
        this.USER_ROLEASSIGNMENT.replace(
          IdPParamKeys.ManagementGroupId,
          managementGroupId
        ).replace(IdPParamKeys.UserSub, userSub)
    );

    return await lastValueFrom(role$);
  }

  async refresh(
    refresh_token: string,
    managementGroup: string
  ): Promise<IdPAccessTokenResponse> {
    const basicAuthBase64 = btoa(
      `${this.passerIdP.options.clientId}:${this.passerIdP.options.clientSecret}`
    );
    const requestHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${basicAuthBase64}`,
    });

    const accessTokenRequest: IdPRefreshTokenRequest = {
      grant_type: 'refresh_token',
      refresh_token,
      scope: this.passerIdP.options.scope,
      managementGroup,
    };

    const refresh$ = this.http.post<IdPAccessTokenResponse>(
      this.passerIdP.options.serverUrl + this.TOKEN,
      this.methodGeneral.toQueryString(accessTokenRequest),
      { headers: requestHeaders }
    );

    return await lastValueFrom(refresh$);
  }

  refreshToken(
    refresh_token: string,
    managementGroup: string
  ): Observable<IdPAccessTokenResponse> {
    const basicAuthBase64 = btoa(
      `${this.passerIdP.options.clientId}:${this.passerIdP.options.clientSecret}`
    );
    const requestHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${basicAuthBase64}`,
    });

    const accessTokenRequest: IdPRefreshTokenRequest = {
      grant_type: 'refresh_token',
      refresh_token,
      scope: this.passerIdP.options.scope,
      managementGroup,
    };

    return this.http.post<IdPAccessTokenResponse>(
      this.passerIdP.options.serverUrl + this.TOKEN,
      this.methodGeneral.toQueryString(accessTokenRequest),
      { headers: requestHeaders }
    );
  }

  async recoverPassword(email: string): Promise<ResponseGeneral> {
    const recover$ = this.http.post<ResponseGeneral>(
      this.passerIdP.options.serverUrl + this.RECOVER_PASSWORD,
      {
        email,
        callbackBaseUrl: this.passerIdP.options.callbackRecoveryPasswordBaseUrl,
      }
    );

    return await lastValueFrom(recover$);
  }

  async sendCode(channel: string): Promise<ResponseGeneral> {
    const code$ = this.http.post<ResponseGeneral>(this.url + this.REQUEST, {
      channel,
    });

    return await lastValueFrom(code$);
  }

  async verifyCode(verificationCode: string): Promise<ResponseLogin> {
    const verify$ = this.http.post<ResponseLogin>(this.url + this.VERIFY, {
      verificationCode,
    });

    return await lastValueFrom(verify$);
  }

  async verifyCodeRecover(
    verificationCode: string,
    email: string
  ): Promise<ResponseGeneral> {
    const recover$ = this.http.post<ResponseGeneral>(
      this.url + this.VERIFY_RESET,
      {
        verificationCode,
        email,
      }
    );

    return await lastValueFrom(recover$);
  }

  async resetPassword(
    password: string,
    confirmPassword: string
  ): Promise<ResponseGeneral> {
    const reset$ = this.http.post<ResponseGeneral>(
      this.passerIdP.options.serverUrl + this.CHANGE_PASSWORD,
      {
        password,
        confirmPassword,
      }
    );

    return await lastValueFrom(reset$);
  }

  async logout(
    userSub: string,
    params: { managementGroup: string }
  ): Promise<ResponseGeneral> {
    const logout$ = this.http.post<ResponseGeneral>(
      this.passerIdP.options.serverUrl +
        this.LOGOUT.replace(IdPParamKeys.UserSub, userSub),
      params
    );

    return await lastValueFrom(logout$);
  }

  async logoutMethod(unauthorized = false, forceClose = false): Promise<void> {
    try {
      const user: User = await this.storage.getData(StorageKeys.USER);
      const managementGroup: string = await this.storage.getData(
        StorageKeys.ACTIVE_MANAGEMENT_GROUP
      );
      const response: ResponseGeneral = await this.logout(user?.sub, {
        managementGroup,
      });
      const { success, message } = response;
      if (success) {
        await this.clearDataLogout(unauthorized);
      } else {
        if (forceClose) {
          await this.clearDataLogout(unauthorized);
        } else {
          this.sharedService.showSnackBar('', message);
        }
      }
    } catch (error) {
      this.loading.dismissLoading();
      if (forceClose) {
        await this.clearDataLogout(unauthorized);
      } else {
        if (error?.title && error?.message) {
          this.sharedService.showSnackBar(error.title, error.message);
        }
      }
    }
  }

  async clearDataLogout(unauthorized = false): Promise<void> {
    const response: boolean = await this.storage.clearAllData();
    if (response) {
      this.loading.dismissLoading();
      await this.router.navigate([ROUTES_ADMIN.LOGIN_ROUTE], {
        replaceUrl: true,
      });
      this.modalService.closeAllModal();
      this.modalService.closeCurrentModals();
      this.session.clearDataSession();
      this.userManager.clearDataUser();

      if (unauthorized) {
        const {
          global: { passerErrorTitle2, passerErrorMessage2 },
          actions: { understood },
        } = this.translation.getLangMessagesByKeys(['global', 'actions']);
        this.modalService.showAlertComp(NormalModalComponent, {
          title: passerErrorTitle2,
          message: passerErrorMessage2,
          btnSave: understood,
        });
      }
    } else {
      // No deberia entrar acá
      this.loading.dismissLoading();
      const { passer_error_title, passer_error_cache } =
        this.translation.getLangMessagesByKeys('global');
      this.sharedService.showSnackBar(passer_error_title, passer_error_cache);
    }
    this._unauthorized = false;
  }

  async initLogin(
    email: string,
    password: string
  ): Promise<ResponseGeneralMini> {
    try {
      const response: IdPAccessTokenResponse = await this.loginStart(
        email,
        password
      );
      if (response) {
        return { success: true, data: response };
      } else {
        return { success: false, data: '' };
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return { success: false, data: error?.data };
    }
  }

  async loginUserInfo(): Promise<ResponseGeneralMini> {
    try {
      const response: User = await this.userInfo();
      if (response) {
        return { success: true, data: response };
      } else {
        const { passer_error_title, passer_error_cache } =
          this.translation.getLangMessagesByKeys('global');
        this.sharedService.showSnackBar(passer_error_title, passer_error_cache);
        return { success: false };
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return { success: false };
    }
  }

  async loginManagementGroupsInfo(sub: string): Promise<ResponseGeneralMini> {
    try {
      const response: ManagementGroup[] =
        await this.userManagementGroupsInfo(sub);
      if (response?.length === 0) {
        const { passer_error_title, passer_error_cache } =
          this.translation.getLangMessagesByKeys('global');
        this.sharedService.showSnackBar(passer_error_title, passer_error_cache);
        return { success: false };
      } else {
        if (response.length === 1) {
          const {
            passer_error_title,
            passer_idp_error_invalid_management_group,
          } = this.translation.getLangMessagesByKeys('global');
          const managementID = response[0]?._id;
          if (!managementID) {
            this.sharedService.showSnackBar(
              passer_error_title,
              passer_idp_error_invalid_management_group
            );
            return { success: false };
          }
        }
        return { success: true, data: response };
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return { success: false };
    }
  }

  async loginUserRoleAssignment(
    managementId: string,
    sub: string
  ): Promise<ResponseGeneralMini> {
    try {
      const response: RoleAssignment[] = await this.userRoleAssignment(
        managementId,
        sub
      );
      if (response) {
        const roleAssignmented = response.find(
          roleAssignmented => roleAssignmented.scope === managementId
        );
        if (!roleAssignmented) {
          const {
            passer_error_title,
            passer_idp_error_invalid_management_group,
          } = this.translation.getLangMessagesByKeys('global');
          this.sharedService.showSnackBar(
            passer_error_title,
            passer_idp_error_invalid_management_group
          );
          return { success: false };
        } else {
          if (roleAssignmented.roleDefinition) {
            return { success: true, data: roleAssignmented };
          } else {
            return { success: false };
          }
        }
      } else {
        const { passer_error_title, passer_error_cache } =
          this.translation.getLangMessagesByKeys('global');
        this.sharedService.showSnackBar(passer_error_title, passer_error_cache);
        return { success: false };
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return { success: false };
    }
  }

  async logingResfreshToken(
    managementId: string
  ): Promise<ResponseGeneralMini> {
    try {
      const response: IdPAccessTokenResponse = await this.refresh(
        this.session.accessToken.refresh_token,
        managementId
      );
      if (response) {
        this.session.accessToken = response;
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      if (error?.message && error?.title) {
        this.sharedService.showSnackBar(error.title, error.message);
      }
      return { success: false };
    }
  }

  public get unauthorized(): boolean {
    return this._unauthorized;
  }

  public set unauthorized(value: boolean) {
    this._unauthorized = value;
  }
}
