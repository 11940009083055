import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData, CommonModule } from '@angular/common';
import localeCR from '@angular/common/locales/es-CR';
import localeExtraCR from '@angular/common/locales/extra/es-CR';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Drivers } from '@ionic/storage';
import { MaterialModule } from './app-material.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { passerNavAnimation } from '@animations/nav-animation';
import { ErrorInterceptor } from '@providers/error-interceptor/error-interceptor.service';
import { ComponentsModule } from '@components/components.module';
import { SetDateStringService } from '@providers/set-date-string/set-date-string';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

import { PaginationService } from 'ngx-pagination';
import { MethodsGeneralService } from '@providers/methods-general/methods-general';
import { FormatPipe } from '@pipes/format';
import { PasserCryptoService } from '@providers/passer-crypto/passer-crypto.service';
import { AuthInterceptor } from '@providers/auth-interceptor/auth-interceptor.provider';
import { CreditCardPipe } from '@pipes/format-creditcard';

import { PipeModule } from '@pipes/pipes.modules';
import { TranslationService } from '@providers/translation-new/translation.service';
import { FeatureFlagsService } from '@providers/feature-flags/feature-flags.service';

registerLocaleData(localeCR, localeExtraCR);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
      backButtonText: '',
      sanitizerEnabled: true,
      navAnimation: passerNavAnimation,
    }),
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    ComponentsModule,
    IonicStorageModule.forRoot({
      name: '__dea',
      driverOrder: [
        Drivers.IndexedDB,
        Drivers.LocalStorage,
        Drivers.SecureStorage,
      ],
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PipeModule,
  ],
  providers: [
    FeatureFlagsService,
    SetDateStringService,
    PaginationService,
    MethodsGeneralService,
    FormatPipe,
    PasserCryptoService,
    CreditCardPipe,
    HttpClient,
    TranslationService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CR' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
